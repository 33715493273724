<template>
  <div
    :class="['layout']"
    :style="layoutHeight"
  >
    <slot />
    <DebugView
      v-show="$compliance.debug.active"
      ref="debug"
    />
  </div>
</template>

<script>
import DebugView from '@components/DebugView.vue'

export default {
  components: {
    DebugView,
  },
  data() {
    return {
      height: false,
      heightRetry: 20,
      activeCollector: '',
      absoluteFooter: '',
      themeColor: '#ffffff',
    }
  },
  computed: {
    layoutHeight() {
      if (this.height) {
        if (
          this.activeCollector === 'age_gate' ||
          this.absoluteFooter === 'absolutefooter'
        )
          return (
            'min-height: ' + this.height + 'px; height:' + this.height + 'px;'
          )
        else return 'min-height: ' + this.height + 'px;'
        // -20 removes bottom padding
        // else if (this.height) return 'min-height: ' + this.height + 'px;'
      } else return ''
    },
  },
  metaInfo() {
    return {
      meta: [
        { charset: 'utf-8' },
        {
          key: 'viewport',
          name: 'viewport',
          content:
            'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover',
        },
        {
          key: 'theme-color',
          name: 'theme-color',
          content: this.themeColor,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://compliance.outfit7.net' + this.$route.path,
        },
      ],
    }
  },
  mounted() {
    this.setSelfToLibrary()
    this.height = this.$compliance.viewportHeight()
    this.$compliance.debug.log('INFO', 'Layout Mounted EVENT')
    this.$compliance.debug.log(
      'DEBUG',
      'Layout viewport height set to ' + this.height + 'px'
    )
    this.activeCollector = this.$compliance.activeCollector
    // document.body.style.height = this.height + 'px'
    this.themeColor = this.$compliance.backgroundColor

    this.$compliance.DebugView = this.$refs.debug
    this.selfUpdateHeight()
  },
  methods: {
    update(c) {
      this.height = c
      this.absoluteFooter = this.$compliance.props.redrawCorrection
      this.$compliance.debug.log(
        'DEBUG',
        'Layout UPDATE: viewport height set to ' + this.height + 'px'
      )
    },
    setSelfToLibrary() {
      if (this.$compliance) {
        this.$compliance.Layout = this
      } else {
        setTimeout(() => {
          this.setSelfToLibrary()
        }, 100)
      }
    },
    selfUpdateHeight() {
      // We need to do this only for iOS, since Webview loads the page in the background and displays it to user later
      // This messes with the layout and certain elements don't render in correct sizes, a resize event needs to be dispatched to force a redraw
      // Since the page is loaded in background the browser is indicating that it is shown to user, when in fact it's not.
      // Standard detection techniques fail (onpageshow, onload, intersection observer, safe-area-inset, etc.) so we brute force it :(
      if (this.$compliance.isPlatform('ios')) {
        if (this.heightRetry > 0) {
          this.$compliance.debug.log(
              'DEBUG',
              'selfUpdateHeight: waiting for conditions ' + this.heightRetry
          )
          this.heightRetry--
          window.dispatchEvent(new Event('resize'))
          setTimeout(() => {
            this.selfUpdateHeight()
          }, 100)
        }
      }
    },
  },
}
</script>

<style lang="scss">
:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

html {
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  line-height: 1.4;
  font-weight: 700;
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  -webkit-touch-callout: none; /* iOS Safari */
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  &.landscape {
    padding: 0 0 0 0;
  }
  &.mythiclegends, &.triumphoflegends {
    background-color: #241720;
  }
  &.onehelsing {
    background-color: #0A152A;
  }
  &.swampattack2 {
    background-color: #f4e4a6;
  }
  &.ios {
    h1, h2, h3, h4, h5, h6 {
      font-family: 'system-ui', sans-serif;
    }
    font-family: 'system-ui', sans-serif;
  }
  &.android {
    &.zh {
      font-family: 'Noto Sans SC', sans-serif;
    }

    &.zh-hant {
      font-family: 'Noto Sans TC', sans-serif;
    }

    &.ko {
      font-family: 'Noto Sans KR', sans-serif;
    }

    &.ja {
      font-family: 'Noto Sans JP', sans-serif;
    }

    &.ar {
      font-family: 'Noto Naskh Arabic', sans-serif;
    }
  }
}

.layout {
  position: relative;
  margin: 0 auto;
  padding: 0 20px 20px 20px;
  overflow: hidden;

  &.ar {
    direction: rtl;
  }
  .actions {
    align-content: center;
    align-items: center;
    margin: 50px 10px 10px 10px;
    text-align: center;
    .span {
      display: inline-block;
      width: auto;
      padding: 10px;
      margin: 0 auto;
      color: #688194;
      text-align: center;
      text-decoration: underline;
      font-size: 1em;
      @media screen and (min-width: 768px) {
        font-size: 1.4rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.6rem;
      }
    }
  }
  &.tablet {
    padding: 40px;
  }
}
.landscape .layout {
  .actions {
    position: fixed;
    display: block;
    margin: 0 auto;
    width: 100%;
    left: 0;
    bottom: 0;
  }
}
.landscape {
  .layout .regcontainer, .layout .adscontainer, .layout .noadscontainer {
    width: calc(100% - 200px);
    @media screen and (min-width: 1024px) {
      width: calc(100% - 250px);
    }
    .title {
      @media screen and (min-width: 768px) {
        font-size: 2.6em;
      }
      @media screen and (min-width: 1024px) {
        font-size: 4.2em;
      }
    }
    .text,
    .purpose_intro {
      padding: 0 20px 0 20px;
      @media screen and (min-width: 768px) {
        font-size: 1.2em;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.8em;
      }
    }
    .list {
      padding: 30px 10px 0 10px;
      margin: 20px 0 20px 0;
    }
    .list .list_item .list_handle .list_expand {
      @media screen and (min-width: 768px) {
        width: 55px;
        height: 55px;
        flex-basis: 55px;
      }
    }
    .list .list_item .list_handle .list_title {
    }
    .list .list_item .list_handle .list_checkbox {
      @media screen and (min-width: 768px) {
        width: 50px;
        height: 40px;
        flex-basis: 50px;
      }
    }
    .list .list_expanded {
    }
  }
  .layout .adscontainer, .layout .noadscontainer {
    .footer {
      width: calc(100% - 200px);
      @media screen and (min-width: 1024px) {
        width: calc(100% - 250px);
      }
    }
  }
  &.tablet {
    .layout .regcontainer, .layout .adscontainer, .layout .noadscontainer {
      width: calc(100% - 200px);
      @media screen and (min-width: 768px) {
        width: calc(100% - 210px);
      }
      @media screen and (min-width: 1024px) {
        width: calc(100% - 260px);
      }
      .text {
        padding: 0 30px 40px 30px;
        @media screen and (min-width: 768px) {
          font-size: 1.2em;
        }
        @media screen and (min-width: 1024px) {
          font-size: 1.8em;
        }
      }
    }
    .layout .adscontainer, .layout .noadscontainer {
    .footer {
      width: calc(100% - 200px);
      @media screen and (min-width: 768px) {
        width: calc(100% - 210px);
      }
      @media screen and (min-width: 1024px) {
        width: calc(100% - 260px);
      }
    }
  }
  }
}
.landscape {
  .layout .licontainer,
  .layout .partcontainer,
  .layout .optcontainer {
    .list {
      padding: 30px 10px 20px 10px;
    }
  }
  .layout .tcf20container {
    .text {
      padding: 0 20px 20px 20px;
    }
  }
  .piplcontainer.tcf20container {
    .text {
      padding-bottom: 160px;
    }
  }
}
</style>
