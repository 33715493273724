// ***************
// Opt Out handler
//
// @author: Primož Bevk
// ***************
import { resValidate } from '../utils/core'
import { call } from '../utils/native'
import { navigate } from '../utils/flags'
import { setCLSValue } from '../utils/ls'

class Pipl {
  constructor(_ctx) {
    this._ctx = _ctx
    this.onResultSent = false
    this.hasPIPLConsent = false
    this._ctx.debug.log('INFO', 'Pipl INIT')
  }

  // update with new data
  update(lS, spc, resolve) {
    this.onResultSent = false
    this._ctx.debug.log('DEBUG', 'Pipl UPDATE')
    // if O7Compliance_OptOut not set & over ageLimit
    resolve(navigate.NAVIGATE)
  }

  accept() {
    this.hasPIPLConsent = true
    this.confirm()
  }

  reject() {
    this.hasPIPLConsent = false
    this.confirm()
  }

  confirm() {
    var res = {
      rT: '',
    }
    if (!this.onResultSent) {
      this.onResultSent = true
      if (this._ctx.cData) {
        // validate
        this._ctx.cData = resValidate(this._ctx.cData, 'consent_pipl')
        // fill data
        this._ctx.cData.sP.consent_pipl.v = this._ctx.version
        this._ctx.cData.sP.consent_pipl.t = new Date().getTime()
        this._ctx.cData.sP.consent_pipl.r.hasPIPLConsent = this.hasPIPLConsent
        this._ctx.cData.sP.consent_pipl.r.gts = this._ctx.gts
        setCLSValue('hasPIPLConsent', this.hasPIPLConsent, this._ctx)
        this._ctx.cData.gvl = null // remove gvl to save on parsing performance on client

        res.rT = 'success'
        res.p = this._ctx.cData
      } else {
        res.rT = 'break'
        res.failReason = 'CMD was not initialised!'
      }
      this._ctx.debug.log('INFO', 'sendComplianceResult')
      this._ctx.debug.log('DEBUG', JSON.stringify(res))
      call('onResult', JSON.stringify(res), this._ctx)
    } else {
      res.rT = 'break'
      res.failReason = 'Pipl result was already sent!'
      this._ctx.debug.log('INFO', 'logComplianceResult')
      this._ctx.debug.log('DEBUG', JSON.stringify(res))
    }
  }
}

export default Pipl
