import Vue from 'vue'
import router from '@router'

import DefaultLayout from '@layouts/Default.vue'
import Purpose from '@components/tcf20/Purpose.vue'

import { parseUrl } from '@compliance/utils/url'
import { Compliance } from '@compliance'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { RewriteFrames } from '@sentry/integrations'

import App from './App.vue'

const { version } = require('../package.json')

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

const app = new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')

// Set default layout as a global component
Vue.component('Layout', DefaultLayout)
// Globally register the Purpose component (we use it recursively)
Vue.component('Purpose', Purpose)
console.log(process.env.GRIDSOME_O7RELEASE)
Sentry.init({
  Vue: Vue,
  dsn:
    'https://6e23fb2569dd4076a413002fd5349257@o446669.ingest.sentry.io/5565782',
  autoSessionTracking: true,
  logErrors: true,
  integrations: [new Integrations.BrowserTracing(), new RewriteFrames()],
  release: version,
  sampleRate: 0.05,
  tracesSampleRate: 0.005,
  attachStacktrace: true,
  tracingOptions: {
    trackComponents: true,
  },
})
Sentry.setTag('compliance', version)
Sentry.setContext('Compliance module', {
  version: version,
})

// initialise Compliance Module and make it available in Vue & window global scopes
// set defaults
var options = {
  os: 'iphone',
  debug: false,
  release: version,
}
options.path = window.location.pathname
const qp = parseUrl(window.location.search)
if (qp) {
  options = {
    ...options,
    ...qp,
  }
}
const compliance = new Compliance(options)
// make Compliance class globally available
Vue.prototype.$compliance = compliance
// make Vue app instance globally available
Vue.prototype.$compliance._Vue = app
window.compliance = compliance
// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}
