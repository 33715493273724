// ***************
// Compliance Utils
// Client Local Storage Library for Compliance class
//
// @author: Primož Bevk
// ***************

export const CLS = (value, _ctx) => {
  _ctx.cData.lS = Object.assign(_ctx.cData.lS, value)
}

export const keyExists = (key, _ctx) => {
  return _ctx.cData.lS && _ctx.cData.lS[key]
}

export const setCLSValue = (key, value, _ctx) => {
  _ctx.cData.lS[key] = value
}

export const deleteCLSValue = (key, _ctx) => {
  _ctx.cData.lS[key] = null
}

// delete local storage value(s) based on KEY.
// check is done with startsWith as we want to do the cleanup for a specific collector
// which means we will do the checks by the collector prefix (this avoids unintended deletions from other collectors)
export const deleteCLSValueByKey = (key, _ctx) => {
  const storageKeys = Object.keys(_ctx.cData.lS)
  for (var k in storageKeys) {
    if (storageKeys[k].startsWith(key)) deleteCLSValue(storageKeys[k], _ctx)
  }
}