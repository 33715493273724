// ***************
// Gender Gate
// O7 implementation of the Gender Gate collector
//
// _ctx: global Compliance class context
//
// @author: Primož Bevk
// ***************
import { resValidate } from '../utils/core'
import { navigate } from '../utils/flags'
import { call } from '../utils/native'
import { setCLSValue } from '../utils/ls'

class GenderGate {
  constructor(_ctx) {
    this._ctx = _ctx
  }

  update(lS, resolve) {
    this.onResultSent = false

    this._ctx.debug.log('DEBUG', 'GenderGate UPDATE')
    // if O7Compliance_Gender is set, use it's value and auto resolve
    if (lS.O7Compliance_Gender) {
      this.setGender(lS.O7Compliance_Gender)
      this.confirm()
      resolve(navigate.DONT_NAVIGATE)
    } else {
      resolve(navigate.NAVIGATE)
    }
  }

  setGender(gender) {
    this.gender = gender
  }

  getGender() {
    return this.gender
  }

  isGenderSet() {
    return this.gender !== undefined
  }

  confirm() {
    var res = {
      rT: '',
    }
    if (!this.onResultSent) {
      this.onResultSent = true
      if (this._ctx.cData) {
        // validate
        this._ctx.cData = resValidate(this._ctx.cData, 'gender_gate')
        // fill data
        this._ctx.cData.sP.gender_gate.v = this._ctx.version
        this._ctx.cData.sP.gender_gate.t = new Date().getTime()
        this._ctx.cData.sP.gender_gate.r = {} // clear result object, before populating it
        this._ctx.cData.sP.gender_gate.r.gender = this.gender
        this._ctx.cData.sP.gender_gate.r.gts = this._ctx.gts
        setCLSValue('O7Compliance_Gender', this.gender, this._ctx)

        this._ctx.cData.gvl = null // remove gvl to save on parsing performance on client
        res.rT = 'success'
        res.p = this._ctx.cData
      } else {
        res.rT = 'break'
        res.failReason = 'CMD was not initialised!'
      }
      this._ctx.debug.log('INFO', 'sendComplianceResult')
      this._ctx.debug.log('DEBUG', JSON.stringify(res))
      call('onResult', JSON.stringify(res), this._ctx)
    } else {
      res.rT = 'break'
      res.failReason = 'GenderGate result was already sent!'
      this._ctx.debug.log('INFO', 'logComplianceResult')
      this._ctx.debug.log('DEBUG', JSON.stringify(res))
    }
  }
}

export default GenderGate
