// ***************
// Options Menu
// O7 implementation of the Preference Collectors options
//
// _ctx: global Compliance class context
//
// @author: Primož Bevk
// ***************
import { call } from '../utils/native'
import { navigate } from '../utils/flags'

class PreferenceSettings {
  constructor(menu, _ctx) {
    this._ctx = _ctx
    this.update(menu, () => {})
  }

  update(menu, resolve) {
    if (menu === undefined) {
      this.valid = false
    } else {
      this.items = menu // store it so it's available
      this.editable = menu.filter((m) => {
        return m.e === true
      })
      this.valid = true
      this.updateMenu() // pass data to view
    }
    this.onResultSent = false
    this._ctx.debug.log('INFO', 'menu INIT')
    resolve(navigate.NAVIGATE)
  }
  getMenu() {
    const c = this._ctx.getLocalisedContent()
    return this.editable.map(opt => {
      opt.dN = c['m_'+opt.id]
      return opt
    })
  }

  updateMenu() {
    if (this.MenuOptions) {
      this.MenuOptions.update()
    } else {
      setTimeout(() => {
        this.updateMenu()
      }, 100)
    }
  }

  confirm(id) {
    this._ctx.cData.gvl = null // remove gvl to save on parsing performance on client
    var res = { p: this._ctx.cData, rT: 'success' }
    res.p.sPCID = id

    if (!this.onResultSent) {
      this._ctx.debug.log('INFO', 'sendComplianceResult')
      this._ctx.debug.log('DEBUG', JSON.stringify(res))
      this.onResultSent = true
      call('onResult', JSON.stringify(res), this._ctx)
    }
  }
}
export default PreferenceSettings
