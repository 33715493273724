<template>
  <div class="stack">
    <Purpose
      v-for="(purpose, i) in stack"
      :key="i"
      :purpose="purpose"
      :vendor-details="vendorDetails"
      :is-legitimate-interest="isLegitimateInterest"
      @consented="$emit('consented', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'Stack',
  props: {
    stack: Array,
    vendorDetails: Boolean,
    isLegitimateInterest: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    // retrieve purpose & set type
    for (var purpose in this.stack) {
      // make sure we don't try this again if we already retireved purposes & replaced them
      if (typeof this.stack[purpose] === 'number') {
        try {
          this.stack[purpose] = {
            type: 'purpose',
            defaultConsent: this.stack[purpose].defaultConsent,
            ...this.$compliance.GVL.getPurpose('purpose', this.stack[purpose]),
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  },
}
</script>

<style lang="scss">
.stack {
  background: #fff;
  padding: 0 20px;
  margin: 20px 0 20px 0;
}
</style>
