// ***************
// Opt Out handler
//
// @author: Primož Bevk
// ***************
import { resValidate } from '../utils/core'
import { call } from '../utils/native'
import { navigate } from '../utils/flags'
import { setCLSValue } from '../utils/ls'

class OptOut {
  constructor(_ctx) {
    this._ctx = _ctx
    this.version = 1
    this.optout = false // set default mode optout/optin
    this.onResultSent = false
    this._ctx.debug.log('INFO', 'OptOut INIT')
  }

  // update with new data
  update(lS, spc, resolve) {

    // retrieve ageLimit from data
    this.ageLimit = spc.ageLimit ? spc.ageLimit : 13

    this.onResultSent = false
    this._ctx.debug.log('DEBUG', 'OptOut UPDATE')
    // if O7Compliance_OptOut not set & over ageLimit
    if (lS.O7Compliance_OptOut === undefined && this._ctx.isOverAge(this.ageLimit)) {
      this.setOptout(false)
      this.confirm()
      resolve(navigate.DONT_NAVIGATE)
    } else {
      resolve(navigate.NAVIGATE)
    }
  }

  // set/get collection mode
  setOptout(o) {
    this.optout = o
  }

  getOptout() {
    return this.optout
  }

  // getVerion()
  // returns the version number
  getVersion() {
    return this.version
  }

  confirm() {
    var res = {
      rT: '',
    }
    if (!this.onResultSent) {
      this.onResultSent = true
      if (this._ctx.cData) {
        // validate
        this._ctx.cData = resValidate(this._ctx.cData, 'optout')
        // fill data
        this._ctx.cData.sP.optout.v = this._ctx.version
        this._ctx.cData.sP.optout.t = new Date().getTime()
        this._ctx.cData.sP.optout.r.optout = this.optout
        this._ctx.cData.sP.optout.r.gts = this._ctx.gts
        setCLSValue('O7Compliance_OptOut', this.optout, this._ctx)
        this._ctx.cData.gvl = null // remove gvl to save on parsing performance on client

        res.rT = 'success'
        res.p = this._ctx.cData
      } else {
        res.rT = 'break'
        res.failReason = 'CMD was not initialised!'
      }
      this._ctx.debug.log('INFO', 'sendComplianceResult')
      this._ctx.debug.log('DEBUG', JSON.stringify(res))
      call('onResult', JSON.stringify(res), this._ctx)
    } else {
      res.rT = 'break'
      res.failReason = 'OptOut result was already sent!'
      this._ctx.debug.log('INFO', 'logComplianceResult')
      this._ctx.debug.log('DEBUG', JSON.stringify(res))
    }
  }
}

export default OptOut
