// ***************
// Compliance Events
//
// _ctx: global Compliance class context
//
// @author: Primož Bevk
// ***************
import { sendResult, call } from './native'

export const acceptAllEvent = (_ctx) => {
  try {
    // accept all disclosed purposes including legitimate interests
    for (var p in _ctx.disclosedPurposes) {
      if (_ctx.disclosedPurposes[p].type === 'purpose') {
        if (_ctx.disclosedPurposes[p].legitimateInterest)
          _ctx.TCF20.setPurposeLegitimateInterest(_ctx.disclosedPurposes[p].id)
        else _ctx.TCF20.setPurposeConsent(_ctx.disclosedPurposes[p].id)
      } else if (_ctx.disclosedPurposes[p].type === 'stack') {
        // expand purpose stack
        const stack = _ctx.GVL.getConsentStack(_ctx.disclosedPurposes[p].id)
        for (var p2 in stack.purposes) {
          var pid = stack.purposes[p2]
          if (typeof stack.purposes[p2] === 'object')
            pid = stack.purposes[p2].id

          if (_ctx.disclosedPurposes[p].legitimateInterest)
            _ctx.TCF20.setPurposeLegitimateInterest(pid)
          else _ctx.TCF20.setPurposeConsent(pid)
        }
      }
    }
    // accept all vendors
    _ctx.TCF20.setVendorConsent(
      Object.keys(_ctx.GVL.getVendors('all', null)).map(Number)
    )
    // accept all nonIAB
    _ctx.nonIAB.setVendorConsent('all')
    // accept all legitimate interests
    _ctx.TCF20.initDisclosedPurposes(true)
    // send compliance result
    sendResult(_ctx, 'acceptAll')
  } catch (e) {
    _ctx.debug.error(e)
    // sendComplianceResult with fail
    var res = {
      rT: 'break',
      failReason: 'Accept all fail',
    }
    call('onResult', JSON.stringify(res), _ctx)
  }
}
export const rejectAllEvent = (_ctx, rejectLI = false) => {
  try {
    // reject all purposes; keep legitimate interest!!
    for (const p in _ctx.disclosedPurposes) {
      // reject legitimate interest on purpose & stack if rejected from legitimate interest screen
      if (_ctx.disclosedPurposes[p].legitimateInterest &&
          rejectLI) {
        if (_ctx.disclosedPurposes[p].type === 'purpose') {
          _ctx.TCF20.unsetPurposeLegitimateInterest(_ctx.disclosedPurposes[p].id, false)
        } else if (_ctx.disclosedPurposes[p].type === 'stack') {
          // expand purpose stack
          const stack = _ctx.GVL.getConsentStack(_ctx.disclosedPurposes[p].id)
          for (const lp in stack.purposes) {
            let lpid = stack.purposes[lp]
            if (typeof stack.purposes[lp] === 'object')
              lpid = stack.purposes[lp].id

            if (!_ctx.disclosedPurposes[p].legitimateInterest)
              _ctx.TCF20.unsetPurposeLegitimateInterest(lpid)
          }
        }
      } else if (
        _ctx.disclosedPurposes[p].type === 'purpose' &&
        !_ctx.disclosedPurposes[p].legitimateInterest
      ) {
        _ctx.TCF20.unsetPurposeConsent(_ctx.disclosedPurposes[p].id)
      } else if (
        _ctx.disclosedPurposes[p].type === 'stack' &&
        !_ctx.disclosedPurposes[p].legitimateInterest
      ) {
        // expand purpose stack
        const stack = _ctx.GVL.getConsentStack(_ctx.disclosedPurposes[p].id)
        for (var p2 in stack.purposes) {
          var pid = stack.purposes[p2]
          if (typeof stack.purposes[p2] === 'object')
            pid = stack.purposes[p2].id

          if (!_ctx.disclosedPurposes[p].legitimateInterest)
            _ctx.TCF20.unsetPurposeConsent(pid)
        }
      }
    }

    // reject all vendors
    _ctx.TCF20.unsetVendorConsent(
      Object.keys(_ctx.GVL.getVendors('all', null)).map(Number)
    )
    // reject all nonIAB
    _ctx.nonIAB.unsetVendorConsent('all')
    // reject all vendor legitimate interest
    if (rejectLI)
      _ctx.TCF20.unsetVendorLegitimateInterest(
          _ctx.GVL.getVendors('legitimatePurpose', null),
          false
      )
    // send compliance result
    sendResult(_ctx, 'rejectAll')
  } catch (e) {
    _ctx.debug.error(e)
    // sendComplianceResult with fail
    var res = {
      rT: 'break',
      failReason: 'Reject all fail',
    }
    call('onResult', JSON.stringify(res), _ctx)
  }
}
export const saveEvent = (_ctx) => {
  // send compliance result in whatever state the user set it
  sendResult(_ctx, 'save&exit')
}

export const resizeEvent = (func) => {
  window.addEventListener('resize', func)
}
