// ***************
// Compliance Utils
// Utility Library for Compliance class
//
// @author: Primož Bevk
// ***************

// Query param parser
export const parseUrl = (querystring) => {
  const params = new URLSearchParams(querystring)
  const obj = {}

  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key)
    } else {
      obj[key] = params.get(key)
    }
    obj[key] = obj[key].replace('/', '').replace('#', '')
  }
  return obj
}

// retrieve OS version from URL
export const osFromUrl = (querystring) => {
  const qp = parseUrl(querystring)
  if (qp && qp.oV && typeof qp.oV === 'string') {
    const ver = Number(qp.oV.split('.')[0])
    return { os: qp.os, v: ver }
  }
  return false
}

// check for Android 6 edge case
export const isAndroid6 = (querystring, _ctx) => {
  const os = osFromUrl(querystring)
  // if context available, rely on scrollToUnsupported parameter
  if (_ctx && _ctx.customMode === 'scrollToUnsupported') {
    return true
    // alternatively we check version
  } else if (
    os &&
    os.os &&
    os.v &&
    os.v < 7 &&
    os.os.toLowerCase() === 'android'
  ) {
    return true
  }
  return false
}

//
// Router History handler
//
// Store history state, push/pop based on go/goBack actions
// if route cannot be read & we cannot store state, we need to permanently fail the store collection (unreliable)
// as we use this for "go back" android functionality we cannot fully rely on history to tell us when to close
// so we set the history length to -1 which disables the quit functionality on last item in the history store state

export const history = (_ctx) => {
  var historyStore = []
  var currentRoute = false

  var go = (routeFrom, routeTo) => {
    // save route we navigated from
    // make sure we're not just adding back the same route
    if (routeFrom && routeFrom.name != null && routeTo.name !== currentRoute) {
      // push also first route if we failed to do so at the start
      console.log(routeFrom.name + ' -> ' + routeTo.name)
      historyStore.push(routeFrom.name)
    } else {
      _ctx.debug.log('ERROR', "Route undefined, couldn't update history state")
    }
  }
  var goBack = () => {
    // write the route we're navigating back as the currentRoute
    go = historyStore.length > 0 ? historyStore.pop() : false
    currentRoute = go
    return go
  }
  var size = () => {
    return historyStore.length
  }

  return {
    historyStore,
    size,
    go,
    goBack,
  }
}

export const queueHistoryPush = (route) => {
  if (window.compliance) window.compliance.routeHistory.go(route)
  else setTimeout(queueHistoryPush(route), 1000)
}
