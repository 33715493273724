/**
 * ccpa.js
 * https://github.com/InteractiveAdvertisingBureau/CCPA-reference-code/blob/master/src/usprivacy-string.js
 *
 * Implements class UsprivacyString
 *
 * The class contains the methods to get/set the usprivacy string
 * and a method to get the current version.
 *
 * The usprivacy string as the format: ”vnol” where
 *  v = version
 *  n = Notice Given
 *  o = OptedOut
 *  l = lspact (Limited Service Provider Agreement Covered Transaction)
 * Example: “1YYY” Version 1, Notice given, Opted out, LSAPCT in place.
 * Default is null.
 *
 **/
import { resValidate } from '../utils/core'
import { call } from '../utils/native'
import { navigate } from '../utils/flags'
import { setCLSValue } from '../utils/ls'

const validStringRegExp = /^[1][nNyY-][nNyY-][nNyY-]$/

class CCPA {
  constructor(_ctx) {
    this._ctx = _ctx
    this.version = 1
    this.baseString = null // default is null
    this.optout = false // set default mode optout/optout
    this.now = new Date().getFullYear()
    this.onResultSent = false
    this._ctx.debug.log('INFO', 'CCPA INIT')
  }

  // update with new data
  update(lS, resolve) {
    this.onResultSent = false

    this._ctx.debug.log('DEBUG', 'CCPA UPDATE')
    // if USPrivacyString not set & over 16, we assume auto OPT-IN and return answer to client right away
    if (lS.USPrivacyString === undefined && lS.IABUSPrivacy_String === undefined && this._ctx.isOverAge(16)) {
      this.setoptout(false)
      this.confirm()
      resolve(navigate.DONT_NAVIGATE)
    } else {
      if (lS.USPrivacyString) {
        this.setUsprivacyString(lS.USPrivacyString)
      } else if (lS.IABUSPrivacy_String) {
        this.setUsprivacyString(lS.IABUSPrivacy_String)
      } else {
        if (this._ctx.isOverAge(16)) this.setoptout(false)
        else this.setoptout(true)
      }
      resolve(navigate.NAVIGATE)
    }
  }

  // set/get collection mode
  setoptout(optout) {
    this.optout = optout
    this.updateView()
  }

  updateView() {
    if (this.Consent) {
      this.Consent.update(this.optout)
    } else {
      setTimeout(() => {
        this.updateView()
      }, 100)
    }
  }

  getoptout() {
    return this.optout
  }

  // getUsprivacyString()
  // return the usprivacy string or null if an error occurs
  getUsprivacyString() {
    return this.baseString
  }

  // setUsprivacyString(newstr)
  // checks for validity of the string before setting internals
  // returns true if success otherwise false
  validateUsprivacyString(newstr) {
    let didSet = false
    if (validStringRegExp.test(newstr)) {
      this.baseString = newstr
      didSet = true
    }
    return didSet
  }
  setUsprivacyString(newstr) {
    if (validStringRegExp.test(newstr)) {
      this.baseString = newstr
      this.setoptout(newstr.charAt(2) !== 'N')
    }
  }

  // getVerion()
  // returns the version number
  getVersion() {
    return this.version
  }

  confirm() {
    // https://github.com/InteractiveAdvertisingBureau/USPrivacy/blob/master/CCPA/US%20Privacy%20String.md
    const consentString = this.getVersion() + 'Y' + (this.optout ? 'Y' : 'N') + 'N'

    var res = {
      rT: '',
    }
    if (!this.onResultSent) {
      this.onResultSent = true
      if (this.validateUsprivacyString(consentString)) {
        if (this._ctx.cData) {
          // validate
          this._ctx.cData = resValidate(this._ctx.cData, 'consent_ccpa')
          // fill data
          this._ctx.cData.sP.consent_ccpa.v = this._ctx.version
          this._ctx.cData.sP.consent_ccpa.t = new Date().getTime()
          this._ctx.cData.sP.consent_ccpa.r.USPrivacyString = this.getUsprivacyString()
          this._ctx.cData.sP.consent_ccpa.r.IABUSPrivacy_String = this.getUsprivacyString()
          this._ctx.cData.sP.consent_ccpa.r.gts = this._ctx.gts
          setCLSValue('USPrivacyString', this.getUsprivacyString(), this._ctx)
          setCLSValue('IABUSPrivacy_String', this.getUsprivacyString(), this._ctx)
          this._ctx.cData.gvl = null // remove gvl to save on parsing performance on client
          res.rT = 'success'
          res.p = this._ctx.cData
        } else {
          res.rT = 'break'
          res.failReason = 'CMD was not initialised!'
        }
      } else {
        res.rT = 'break'
        res.failReason = 'Invalid USPrivacyString'
      }
      this._ctx.debug.log('INFO', 'sendComplianceResult')
      this._ctx.debug.log('DEBUG', JSON.stringify(res))
      call('onResult', JSON.stringify(res), this._ctx)
    } else {
      res.rT = 'break'
      res.failReason = 'CCPA result was already sent!'
      this._ctx.debug.log('INFO', 'logComplianceResult')
      this._ctx.debug.log('DEBUG', JSON.stringify(res))
    }
  }
}

export default CCPA
