// ***************
// Compliance Flags
// Utility Library for Compliance class
//
// @author: Primož Bevk
// ***************

export const status = {
  0: 'INITIALISING',
  1: 'READY_TO_RECEIVE',
  2: 'READY',
  3: 'COMPLIANCE_FAIL',
  INITIALISING: 0,
  READY_TO_RECEIVE: 1,
  READY: 2,
  COMPLIANCE_FAIL: 3,
}

export const platform = {
  0: 'ANDROID',
  1: 'IOS',
  2: 'WEB',
  3: 'UNSUPPORTED_OS',
  ANDROID: 0,
  IOS: 1,
  WEB: 2,
  UNSUPPORTED_OS: 3,
}

export const modulePath = {
  consent_tcf20: '/tcf20/',
  consent_gdpr: '/consent-gdpr/',
  consent_lgpd: '/consent-lgpd/',
  age_gate: '/age-gate/',
  gender_gate: '/gender-gate/',
  consent_ccpa: '/ccpa/',
  optout: '/opt-out/',
  'preference-settings': '/preference-settings/',
  consent_noads: '/consent-noads/',
  consent_ads: '/consent-ads/',
  consent_pipl: '/pipl/',
  '/tcf20/': 'consent_tcf20',
  '/consent-gdpr/': 'consent_gdpr',
  '/consent-lgpd/': 'consent_lgpd',
  '/age-gate/': 'age_gate',
  '/gender-gate/': 'gender_gate',
  '/ccpa/': 'consent_ccpa',
  '/opt-out/': 'optout',
  '/preference-settings/': 'preference-settings',
  '/consent-noads/': 'consent_noads',
  '/consent-ads/': 'consent_ads',
  '/pipl/': 'consent_pipl',
}

export const navigate = {
  0: 'WAIT',
  1: 'DONT_NAVIGATE',
  2: 'NAVIGATE',
  WAIT: 0,
  DONT_NAVIGATE: 1,
  NAVIGATE: 2,
}

export const background = {
  outfit7: '#ffffff',
  ttf: '#ffffff',
  mythiclegends: '#241720',
  triumphoflegends: '#241720',
  swampattack2: '#f4e4a6',
  o7neo: '#241720'
}
