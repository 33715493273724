<template>
  <div class="debugview">
    <div
      class="toggleview"
      @click="toggleDebugView($event)"
      @touchstart="toggleDebugView($event)"
    >
      DBG
    </div>
    <div
      v-if="consoleOpen"
      id="dc"
      class="debugconsole"
    >
      <div class="debug_scrollable">
        <div
          class="debug_container"
          v-html="content"
        />
      </div>
      <div class="debug_controls">
        <div>
          <div class="debug_option">
            <div class="option_name">
              Collector
            </div>
            <select
              v-model="selectedCollector"
              class="select"
            >
              <option
                v-for="collector in collectors"
                :key="collector"
              >
                {{
                  collector
                }}
              </option>
            </select>
          </div>
          <div class="debug_option">
            <div class="option_name">
              aR
            </div>
            <select
              v-model="selectedAR"
              class="select"
            >
              <option
                v-for="ar in ars"
                :key="ar"
              >
                {{ ar }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="initbutton"
          @click="initialiseController($event)"
          @touchstart="initialiseController($event)"
        >
          Run
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DebugView',
  data() {
    return {
      content: null,
      consoleOpen: true,
      selectedCollector: 'age_gate',
      collectors: [
        'consent_tcf20',
        'age_gate',
        'preference-settings',
        'consent_ccpa',
        'optout',
        'gender_gate',
        'consent_gdpr',
        'consent_lgpd',
        'consent_noads',
      ],
      selectedAR: 'GDPR',
      ars: ['GDPR', 'COPPA', 'PIPL', 'CCPA'],
      sentryCapture: false,
    }
  },
  mounted() {
    if (this.$compliance.debug.active) {
      this.content = this.$compliance.debug.out()
      this.scrollIntoView()
    }
    this.consoleOpen = this.$compliance.debug.console
  },
  methods: {
    update() {
      if (this.$compliance.debug.active) {
        this.content = this.$compliance.debug.out()
        this.scrollIntoView()
      }
    },
    async initialiseController(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      // fetch test instruction data, so we can construct instructions to initialise the selected collector
      const test = await fetch('https://compliance.outfit7.net/o7test.json')
      const spc = await fetch('https://compliance.outfit7.net/o7spc.json')
      if (!test.ok || !spc.ok) {
        console.log('Network response was not OK')
      } else {
        var json = await test.json()
        var spcOpts = await spc.json()
        json.t = this.selectedCollector
        json.p.aR = this.selectedAR
        var i
        for (i in spcOpts) {
          if (i === this.selectedCollector) {
            if (
              this.selectedAR === 'PIPL' &&
              this.selectedCollector === 'age_gate'
            )
              spcOpts[i].p.piplConsentValue = 'true'
            if (this.sentryCapture)
              spcOpts[i].p.sentryCaptureMessageEnabled = 'true'
          }
          json.p.sPC.push(spcOpts[i])
        }
        // execute CMD
        this.$compliance.onComplianceModuleData(json)
      }
    },
    toggleDebugView(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      this.consoleOpen = !this.consoleOpen
      this.$compliance.debug.toggleConsole(this.consoleOpen)
      this.scrollIntoView()
    },
    scrollIntoView() {
      this.consoleOpen = this.$compliance.debug.console
      if (this.consoleOpen) {
        this.$nextTick(function () {
          var container = document.getElementById('dc')
          container.scrollTop = container.scrollHeight
        })
      }
    },
  },
}
</script>

<style lang="scss">
.debugview {
  .toggleview {
    position: fixed;
    top: 270px;
    left: 5px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #d33a3a;
    width: 32px;
    height: 32px;
    font-size: 0.7em;
    text-align: center;
    line-height: 3em;
    color: #fff;
  }
  .debugconsole {
    position: fixed;
    width: 100%;
    height: 200px;
    top: 65px;
    left: 0;
    z-index: 9999;
    .debug_scrollable {
      position: relative;
      overflow-x: none;
      overflow-y: scroll;
      background: rgba(0, 0, 0, 0.75);
      width: 100%;
      height: 150px;
      color: #fff;
      .debug_container {
        margin: 10px;
        font-size: 10px;
        .debug {
          color: rgb(255, 255, 83);
          line-break: anywhere;
        }
        .error {
          color: rgb(255, 95, 95);
        }
      }
    }
    .debug_controls {
      position: relative;
      display: flex;
      background: rgba(0, 0, 0, 0.75);
      border-top: 2px solid #fff;
      .debug_option {
        display: flex;
        .option_name {
          font-size: 1em;
          padding: 0 10px;
          color: #fff;
        }
      }
      .initbutton {
        padding: 10px 20px;
        font-size: 1.2em;
        text-align: center;
        background: rgb(255, 95, 95);
        color: #fff;
        margin-left: auto;
      }
    }
  }
}
</style>
