var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['purpose', _vm.purpose.type === 'nonIAB' ? 'nonIAB' : '']},[(_vm.purpose.type === 'nonIAB')?_c('div',{staticClass:"purpose_intro"},[_vm._v(" "+_vm._s(_vm.content.nIAB_text)+" ")]):_vm._e(),_c('div',{staticClass:"purpose_handle"},[_c('div',{class:['purpose_expand', _vm.expanded === true ? 'expanded' : ''],on:{"touchend":function($event){return _vm.expand($event, 'purpose')},"click":function($event){return _vm.expand($event, 'purpose')}}},[_vm._v(" > ")]),_c('div',{staticClass:"purpose_title"},[_vm._v(" "+_vm._s(_vm.purpose.name)+" ")]),(
        !_vm.purpose.hideToggle &&
          _vm.purpose.type !== 'specialPurpose'
      )?_c('div',{class:['purpose_checkbox', _vm.isLegitimateInterest ? (_vm.hasLegitimateInterest ? 'checked' : '') : (_vm.consented ? 'checked' : '')],on:{"touchend":function($event){return _vm.toggleConsent($event)},"click":function($event){return _vm.toggleConsent($event)}}},[_vm._v(" "+_vm._s("cb_" + _vm.purpose.id)+" ")]):_vm._e()]),_c('div',{class:['purpose_expanded', _vm.expanded === true ? 'expand' : '']},[_c('div',{staticClass:"purpose_description"},[_vm._v(" "+_vm._s(_vm.purpose.description)+" ")]),(_vm.purpose.descriptionLegal)?_c('div',{staticClass:"title_to_expand",on:{"touchend":function($event){return _vm.expand($event, 'legal')},"click":function($event){return _vm.expand($event, 'legal')}}},[_c('div',{class:['legal_expand', _vm.expandedLegal === true ? 'expanded' : '']},[_vm._v(" > ")]),_c('div',{staticClass:"legal_title"},[_vm._v(" "+_vm._s(_vm.content.pp_legal)+" ")])]):_vm._e(),(_vm.purpose.descriptionLegal)?_c('div',{class:[
        'purpose_description_legal',
        _vm.expandedLegal === true ? 'expand' : '' ]},[_vm._v(" "+_vm._s(_vm.purpose.descriptionLegal)+" ")]):_vm._e(),(_vm.numberOfVendors > 0)?_c('div',{staticClass:"purpose_description paragraph"},[_vm._v(" "+_vm._s(_vm.content.number_of_vendors)+" "+_vm._s(_vm.numberOfVendors)+" ")]):_vm._e(),(_vm.purpose.type === 'specialPurpose' && _vm.purpose.id === 3)?_c('div',{staticClass:"purpose_description paragraph"},[_vm._v(" "+_vm._s(_vm.content.v_data_retention)+": 390 "+_vm._s(_vm.content.v_days)+". ")]):_vm._e(),(_vm.purpose.illustrations && _vm.purpose.illustrations.length > 0)?_c('div',{staticClass:"title_to_expand",on:{"touchend":function($event){return _vm.expand($event, 'example')},"click":function($event){return _vm.expand($event, 'example')}}},[_c('div',{class:['legal_expand', _vm.expandedExample === true ? 'expanded' : '']},[_vm._v(" > ")]),_c('div',{staticClass:"legal_title"},[_vm._v(" "+_vm._s(_vm.content.pp_example)+" ")])]):_vm._e(),(_vm.purpose.illustrations && _vm.purpose.illustrations.length > 0)?_c('div',{class:[
        'purpose_description_legal',
        _vm.expandedExample === true ? 'expand' : '' ]},[_vm._v(" "+_vm._s(_vm.purpose.illustrations[0])+" ")]):_vm._e(),(_vm.purpose.type === 'nonIAB' || _vm.purpose.type === 'purpose')?_c('div',{staticClass:"title_to_expand"},[_c('RouterLink',{staticClass:"vendor_title partners_link",attrs:{"to":"/tcf20/vendors/"}},[_vm._v(" "+_vm._s(_vm.content.a_showvendors)+" ")])],1):_vm._e(),(
        _vm.purpose.type === 'stack' || _vm.purpose.type === 'specialPurposeBlock'
      )?_c('Stack',{attrs:{"stack":_vm.stack,"vendor-details":_vm.vendorDetails,"is-legitimate-interest":_vm.isLegitimateInterest},on:{"consented":function($event){return _vm.stackHasConsented($event)}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }