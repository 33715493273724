<template>
  <div :class="['purpose', purpose.type === 'nonIAB' ? 'nonIAB' : '']">
    <div
      v-if="purpose.type === 'nonIAB'"
      class="purpose_intro"
    >
      {{ content.nIAB_text }}
    </div>
    <div class="purpose_handle">
      <div
        :class="['purpose_expand', expanded === true ? 'expanded' : '']"
        @touchend="expand($event, 'purpose')"
        @click="expand($event, 'purpose')"
      >
        >
      </div>
      <div class="purpose_title">
        {{ purpose.name }}
      </div>
      <div
        v-if="
          !purpose.hideToggle &&
            purpose.type !== 'specialPurpose'
        "
        :class="['purpose_checkbox', isLegitimateInterest ? (hasLegitimateInterest ? 'checked' : '') : (consented ? 'checked' : '')]"
        @touchend="toggleConsent($event)"
        @click="toggleConsent($event)"
      >
        {{ "cb_" + purpose.id }}
      </div>
    </div>
    <div :class="['purpose_expanded', expanded === true ? 'expand' : '']">
      <div class="purpose_description">
        {{ purpose.description }}
      </div>
      <div
        v-if="purpose.descriptionLegal"
        class="title_to_expand"
        @touchend="expand($event, 'legal')"
        @click="expand($event, 'legal')"
      >
        <div
          :class="['legal_expand', expandedLegal === true ? 'expanded' : '']"
        >
          >
        </div>
        <div class="legal_title">
          {{ content.pp_legal }}
        </div>
      </div>
      <div
        v-if="purpose.descriptionLegal"
        :class="[
          'purpose_description_legal',
          expandedLegal === true ? 'expand' : '',
        ]"
      >
        {{ purpose.descriptionLegal }}
      </div>
      <div
        v-if="numberOfVendors > 0"
        class="purpose_description paragraph"
      >
        {{ content.number_of_vendors }} {{ numberOfVendors }}
      </div>
      <div
        v-if="purpose.type === 'specialPurpose' && purpose.id === 3"
        class="purpose_description paragraph"
      >
        {{ content.v_data_retention }}: 390 {{ content.v_days }}.
      </div>
      <div
        v-if="purpose.illustrations && purpose.illustrations.length > 0"
        class="title_to_expand"
        @touchend="expand($event, 'example')"
        @click="expand($event, 'example')"
      >
        <div
          :class="['legal_expand', expandedExample === true ? 'expanded' : '']"
        >
          >
        </div>
        <div class="legal_title">
          {{ content.pp_example }}
        </div>
      </div>
      <div
        v-if="purpose.illustrations && purpose.illustrations.length > 0"
        :class="[
          'purpose_description_legal',
          expandedExample === true ? 'expand' : '',
        ]"
      >
        {{ purpose.illustrations[0] }}
      </div>
      <div
        v-if="purpose.type === 'nonIAB' || purpose.type === 'purpose'"
        class="title_to_expand"
      >
        <RouterLink
          class="vendor_title partners_link"
          to="/tcf20/vendors/"
        >
          {{ content.a_showvendors }}
        </RouterLink>
      </div>
      <Stack
        v-if="
          purpose.type === 'stack' || purpose.type === 'specialPurposeBlock'
        "
        :stack="stack"
        :vendor-details="vendorDetails"
        :is-legitimate-interest="isLegitimateInterest"
        @consented="stackHasConsented($event)"
      />
    </div>
  </div>
</template>

<script>
import Stack from './Stack.vue'

export default {
  name: 'Purpose',
  components: {
    Stack,
  },
  props: {
    purpose: {
      type: Object,
      default: null,
    },
    vendorDetails: {
      type: Boolean,
      default: false,
    },
    isLegitimateInterest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
      expandedLegal: false,
      expandedExample: false,
      expandedVendors: false,
      consented: false,
      hasLegitimateInterest: false,
      vendors: null,
      stack: {},
      content: {},
      numberOfVendors: 0,
    }
  },
  watch: {
    'purpose.defaultConsent': function (newVal, oldVal) {
      // watch for changes in consent; request update if changes
      if (newVal !== oldVal) {
        if (this.purpose.type === 'nonIAB') {
          this.consented = this.$compliance.nonIAB.hasConsented()
        } else {
          this.consented = this.$compliance.TCF20.getPurposeConsent(
            this.purpose.id
          )
        }
      }
    },
  },
  created() {
    this.consented = this.purpose.defaultConsent

    if (this.purpose.type === 'stack') this.stack = this.purpose.purposes
    if (this.purpose.type === 'specialPurposeBlock') {
      for (var purpose in this.purpose.purposes) {
        // make sure we don't try this again if we already retireved purposes & replaced them
        if (!('name' in this.purpose.purposes[purpose])) {
          try {
            this.purpose.purposes[purpose] = {
              ...this.purpose.purposes[purpose],
              ...this.$compliance.GVL.getPurpose(
                this.purpose.purposes[purpose].type,
                this.purpose.purposes[purpose].id
              ),
            }
          } catch (e) {
            console.log(e)
          }
        }
      }
      this.stack = this.purpose.purposes
    }
  },
  mounted() {
    this.content = this.$compliance.getLocalisedContent()
    if (this.purpose.type === 'specialPurposeBlock') {
      this.purpose.name = this.content.sP_name
    }
    if (this.purpose.type === 'nonIAB') {
      this.consented = this.$compliance.nonIAB.hasConsented()
      this.purpose.name = this.content.nIAB_name
    } else if (this.purpose.type === 'stack') {
      this.consented = false
      var nrConsents = 0
      for (var purpose in this.purpose.purposes) {
        // make sure we don't try this again if we already retireved purposes & replaced them
        if (
          this.$compliance.TCF20.getPurposeConsent(
            this.purpose.purposes[purpose].id
          )
        ) {
          nrConsents++
        }
      }
      if (nrConsents === this.purpose.purposes.length) {
        this.consented = true
      }
    } else {
      if (this.isLegitimateInterest)
        this.hasLegitimateInterest = this.$compliance.TCF20.getPurposeLegitimateInterest(
          this.purpose.id
        )
      else
        this.consented = this.$compliance.TCF20.getPurposeConsent(
          this.purpose.id
        )
    }
    if (this.purpose.type !== 'stack') {
      this.$compliance.GVL.getVendorsForPurposeType(this.purpose.id, this.purpose.type, this.isLegitimateInterest).then((size) => {
        this.numberOfVendors = size
      })
    }
  },
  methods: {
    expand(event, type) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      if (type === 'legal') this.expandedLegal = !this.expandedLegal
      if (type === 'example') this.expandedExample = !this.expandedExample
      if (type === 'purpose') this.expanded = !this.expanded
    },
    toggleStackConsent(consent) {
      for (var purpose in this.purpose.purposes) {
        if (!consent)
          this.$compliance.TCF20.unsetPurposeConsent(
            this.purpose.purposes[purpose].id
          )
        else
          this.$compliance.TCF20.setPurposeConsent(
            this.purpose.purposes[purpose].id
          )
      }
    },
    toggleConsent(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      if (this.purpose.type === 'nonIAB') {
        this.consented = !this.consented
        if (!this.consented) this.$compliance.nonIAB.unsetVendorConsent('all')
        else this.$compliance.nonIAB.setVendorConsent('all')
      } else {
        this.hasLegitimateInterest = !this.hasLegitimateInterest
        // if is legitimate interest register objection
        if (this.isLegitimateInterest) {
          if (this.hasLegitimateInterest)
            this.$compliance.TCF20.setPurposeLegitimateInterest(this.purpose.id)
          else
            this.$compliance.TCF20.unsetPurposeLegitimateInterest(
              this.purpose.id
            )
        } else {
          this.consented = !this.consented
          if (this.purpose.type === 'stack') {
            this.toggleStackConsent(this.consented)
            this.stack = Object.values(this.stack).map((el) => {
              return { ...el, defaultConsent: this.consented }
            })
          } else {
            if (!this.consented)
              this.$compliance.TCF20.unsetPurposeConsent(this.purpose.id)
            else this.$compliance.TCF20.setPurposeConsent(this.purpose.id)
          }
        }
      }
      this.$emit('consented', this.consented)
    },
    stackHasConsented(event) {
      // only stack purpose types have children
      if (this.purpose.type === 'stack') {
        this.consented = false
        var nrConsents = 0
        for (var purpose in this.purpose.purposes) {
          // make sure we don't try this again if we already retireved purposes & replaced them
          if (
              this.$compliance.TCF20.getPurposeConsent(
                  this.purpose.purposes[purpose].id
              )
          ) {
            nrConsents++
          }
        }
        if (nrConsents === this.purpose.purposes.length) {
          this.consented = true
        }
      }
    }
  },
}
</script>

<style lang="scss">
.purpose {
  position: relative;
  margin: 0 0 8px 0;
  color: #4c5d6a;
  background: #fff;
  .purpose_intro {
    color: #4c5d6a;
    font-size: 1.1em;
    text-align: center;
    margin: 0;
    padding: 20px 10px 30px 10px;
    span {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
  .purpose_handle {
    display: flex;
    align-items: center;

    .purpose_expand {
      position: relative;
      flex-basis: 40px;
      justify-self: start;
      width: 40px;
      height: 40px;
      overflow: hidden;
      text-indent: -9999px;
      background-image: url('../../assets/images/btn_expand.png');
      background-repeat: no-repeat;
      background-size: contain;
      transition: transform 0.5s;
      transform: rotate(-90deg);
      &.expanded {
        transform: rotate(0);
      }
      @media screen and (min-width: 768px) {
        width: 55px;
        height: 55px;
        flex-basis: 55px;
      }
    }
    .purpose_title {
      flex-basis: calc(100% - 70px);
      align-self: center;
      font-size: 1.2em;
      line-height: 1.2em;
      @media screen and (min-width: 768px) {
        font-size: 1.4rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.8rem;
      }
    }
    .purpose_checkbox {
      flex-basis: 40px;
      width: 40px;
      height: 30px;
      text-indent: -9999px;
      background-image: url('../../assets/images/btn_checkbox_empty.png');
      background-repeat: no-repeat;
      background-size: contain;
      &.checked {
        background-image: url('../../assets/images/btn_checkbox.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      @media screen and (min-width: 768px) {
        width: 50px;
        height: 40px;
        flex-basis: 50px;
      }
    }
    .purpose_object {
      flex-basis: 40px;
      width: auto;
      height: 30px;
      color: #688194;
      text-decoration: underline;
      font-size: 1.2em;
      line-height: 1.2em;
      @media screen and (min-width: 768px) {
        font-size: 1.4rem;
        flex-basis: 120px;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.8rem;
      }
    }
  }

  .title_to_expand {
    display: flex;
    align-items: center;
    margin: 10px 0 0 0;
    .partners_link {
      color: #688194;
      text-decoration: underline;
    }
  }
  .purpose_expanded {
    display: none;
    height: 0;
    padding: 0 20px;
    margin: 5px 0 5px 0;
    font-size: 1em;
    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
    }
    @media screen and (min-width: 1024px) {
      font-size: 1.8rem;
    }
    &.expand {
      display: block;
      height: auto;
    }
    .purpose_description {
      font-size: 1em;
      @media screen and (min-width: 768px) {
        font-size: 1.2rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.4rem;
      }
      &.paragraph {
        margin-top: 10px;
      }
    }
    .legal_expand {
      position: relative;
      justify-self: start;
      width: 20px;
      height: 20px;
      overflow: hidden;
      text-indent: -9999px;
      background-image: url('../../assets/images/btn_expand.png');
      background-repeat: no-repeat;
      background-size: contain;
      transition: transform 0.5s;
      transform: rotate(-90deg);
      &.expanded {
        transform: rotate(0);
      }
      @media screen and (min-width: 768px) {
        width: 55px;
        height: 55px;
      }
    }
    .purpose_description_legal {
      display: none;
      height: 0;
      margin: 5px 0 5px 0;
      font-size: 1em;
      &.expand {
        display: block;
        height: auto;
      }
      @media screen and (min-width: 768px) {
        font-size: 1.2rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.4rem;
      }
    }
    .vendors_expand {
      position: relative;
      justify-self: start;
      width: 20px;
      height: 20px;
      overflow: hidden;
      text-indent: -9999px;
      background-image: url('../../assets/images/btn_expand.png');
      background-repeat: no-repeat;
      background-size: contain;
      transition: transform 0.5s;
      transform: rotate(-90deg);
      &.expanded {
        transform: rotate(0);
      }
      @media screen and (min-width: 768px) {
        width: 55px;
        height: 55px;
      }
    }
    .purpose_description_vendors {
      display: none;
      height: 0;
      margin: 5px 0 5px 0;
      font-size: 1em;
      &.expand {
        display: block;
        height: auto;
      }
      @media screen and (min-width: 768px) {
        font-size: 1.4rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.8rem;
      }
    }
  }
}
.ar .purpose .purpose_handle .purpose_expand,
.ar .purpose .purpose_handle .legal_expand,
.ar .purpose .purpose_handle .vendors_expand {
  transform: rotate(90deg);
  &.expanded {
    transform: rotate(0);
  }
}
</style>
