// ***************
// Compliance class
//
// Manages consent collection & communication between Native client and web app
// Implements the whole Compliance collection; supports following Regulation/Compliance Modules:
// - TCF 2.0: uses locally stored vendor-list.json and localised screens.json to display appropriate consent screens
// - non IAB vendor consents
// - Age Gate
// - CCPA
// - Opt Out: Generalised multi purpose opt out collector
//
// @author: Primož Bevk
// ***************
import * as Sentry from '@sentry/vue'

// import utility libraries
import { history } from './utils/url'
import { status, platform } from './utils/flags'
import {
  isPlatform,
  setPlatform,
  setAppName,
  setBackground,
  osVersion,
  storeName,
  cmVersion,
  setLocale,
  setOrientation,
  setDeviceType,
  setPublisher,
  getViewportHeight,
} from './utils/core'
import { Debug, Logger } from './utils/debug'
import { Delegate } from './utils/delegate'

import { onCMD, call, openExternalUrl } from './utils/native'

// import compliance modules
import GVLModel from './modules/gvl'
import TCF20 from './modules/tcf20'
import NonIAB from './modules/noniab'
import CCPA from './modules/ccpa'
import OptOut from './modules/optout'
import AgeGate from './modules/agegate'
import GenderGate from './modules/gendergate'
import Pipl from './modules/pipl'
import PreferenceSettings from './modules/preferencesettings'

// import data & settings
import screens from './data/screens.json'
import { resizeEvent } from './utils/events'

const packageJson = require('../../package.json')
const version = packageJson.version || 0

export class Compliance {
  constructor(options) {
    Sentry.setTag('appId', options.aID ? options.aID : false)

    this.options = options

    // enrich Sentry events
    if (options.uid) Sentry.setUser({ id: options.uid })
    Sentry.setTag('appId', options.aID || false)
    Sentry.setTag('appVersion', options.appVersion || false)

    // init compliance module
    this.debug =
      options.debug === 'true' || options.debug === true
        ? new Debug(this)
        : new Logger(this)
    this.setProp('os', setPlatform(options.os, this), true)
    this.setProp('version', version)
    this.setProp('osVersion', osVersion(options.oV || false, this))
    this.setProp('cmV', cmVersion(options.cmV || false, this))
    this.setProp('appId', options.aID || false, true)
    this.setProp('appVersion', options.appVersion || false)
    this.setProp('publisher', setPublisher(this.appId), true)
    this.setProp('appName', setAppName(this.appId, this), true)
    this.setProp('storeName', storeName(options.sN, this) || false)
    this.setProp('backgroundColor', setBackground(this))
    this.setProp('uid', options.uid || false)
    this.setProp('legacyMode', false)
    this.setProp('lang', setLocale(options.aL, this), true)
    this.setProp('cmp', screens.cmp)
    this.setProp('iS', options.iS || false)
    this.setProp('consentScreens', screens.cmp.consentScreens)
    this.setProp(
      'screenOrientation',
      setOrientation(this.appId, options.o),
      true
    )
    this.debug.log('DEBUG', 'qp: ' + JSON.stringify(options))
    this.setProp('device', setDeviceType(), true)
    this.setProp(
      'module',
      options.path !== null ? options.path.replace(/^\/|\/$/g, '') : undefined
    )
    // start routeHistory
    this.setProp('routeHistory', history(this))
    // enable delegation of functions to background threads
    this.delegate = new Delegate(this)

    this.disclosedPurposes = []
    this.restrictedPurposes = []
    this.setProp('status', status.INITIALISING)

    resizeEvent(() => {
      this.debug.log('INFO', 'Resize EVENT')
      // fix for android having height of 0 (initialises in background with no viewport size and is shown to user only after all events are finished);
      this.updateLayoutHeight()
    })

    // addEventListener("pageshow", (event) => {
    //   this.debug.log("INFO", "PageShow EVENT")
    //   window.dispatchEvent(new Event('resize'))
    // });

    // signal READY_TO_RECEIVE status to client
    this.executeOnReady(() => {
      this.native('getComplianceModuleData', false)
    })
  }

  initCollector(type) {
    if (type === 'consent_tcf20' && !this.TCF20) {
      this.GVL = new GVLModel(this)
      this.TCF20 = new TCF20(false, this)
      this.nonIAB = new NonIAB(this)
    }
    if (type === 'age_gate' && !this.AgeGate) {
      this.AgeGate = new AgeGate(undefined, this)
    }
    if (type === 'preference-settings' && !this.PreferenceSettings) {
      this.PreferenceSettings = new PreferenceSettings(undefined, this)
    }
    if (type === 'consent_ccpa' && !this.CCPA) {
      this.CCPA = new CCPA(this)
    }
    if (type === 'optout' && !this.OptOut) {
      this.OptOut = new OptOut(this)
    }
    if (type === 'gender_gate' && !this.GenderGate) {
      this.GenderGate = new GenderGate(this)
    }
    if (type === 'consent_gdpr' && !this.nonIAB) {
      this.nonIAB = new NonIAB(this)
    }
    if (type === 'consent_lgpd' && !this.nonIAB) {
      this.nonIAB = new NonIAB(this)
    }
    if (type === 'consent_noads' && !this.nonIAB) {
      this.nonIAB = new NonIAB(this)
    }
    if (type === 'consent_ads' && !this.nonIAB) {
      this.nonIAB = new NonIAB(this)
    }
    if (type === 'consent_pipl' && !this.Pipl) {
      this.Pipl = new Pipl(this)
    }
    this.setProp('activeCollector', type)
    this.setProp('collectorResultSent', false)
  }

  setProp(p, v, c = false) {
    this[p] = v
    if (c) this.extendGlobalClass(p, v)
    this.debug.log('DEBUG', p + ' set to ' + this.typeFormat(p, v))
  }

  extendGlobalClass(p, v) {
    this.props = this.props || {}
    this.props[p] = v
    this.updateAppClass()
  }

  updateAppClass() {
    if (this.App) {
      this.App.update(Object.values(this.props).join(' '))
      document.body.className = Object.values(this.props).join(' ')
        .replace(platform.IOS, platform[platform.IOS].toLowerCase())
        .replace(platform.ANDROID, platform[platform.ANDROID].toLowerCase())
    } else {
      setTimeout(() => {
        this.updateAppClass()
      }, 100)
    }
  }

  updateLayoutHeight() {
    if (this.Layout) {
      this.Layout.update(this.viewportHeight())
    } else {
      setTimeout(() => {
        this.updateLayoutHeight()
      }, 100)
    }
  }

  updateAgeGateSlider() {
    if (this.AgeGate && this.AgeGate.Slider) {
      this.AgeGate.Slider.update()
      this.debug.log('DEBUG', 'Update AgeGate slider')
    } else {
      setTimeout(() => {
        this.updateAgeGateSlider()
      }, 100)
    }
  }

  getPolicyUrl(link) {
    // Return requested url based on set store and language (fallback to store: O7, lang: en)
    try {
      if (Object.hasOwnProperty.call(screens.policyUrl, this.storeName)) {
        if (
          Object.hasOwnProperty.call(
            screens.policyUrl[this.storeName],
            this.lang
          )
        )
          return screens.policyUrl[this.storeName][this.lang][link]
        else return screens.policyUrl[this.storeName].en[link]
      } else {
        if (Object.hasOwnProperty.call(screens.policyUrl.o7, this.lang))
          return screens.policyUrl.o7[this.lang][link]
        else return screens.policyUrl.o7.en[link]
      }
    } catch (e) {
      this.debug.error("getPolicyUrl() '" + link + "' couldn't be set: " + e)
    }
  }

  // get Query Params String
  getQPS(url) {
    const publisherUrls = /^(https:\/\/talkingtomandfriends|https:\/\/outfit7|https:\/\/hyperdotstudios)/
    if (publisherUrls.test(url)) {
      const uid = this.uid ? `&uid=${this.uid}` : '' // when requested from web uid is not provided
      const agp = this.cData?.lS?.O7Compliance_AgeLimitPassed ? `&aGP=${this.cData?.lS?.O7Compliance_AgeLimitPassed}` : ''
      return `${url}?utm_source=${this.appId}${uid}${agp}`
    }
    return url
  }

  privacyUrl(customPublisher = false) {
    return new Promise((resolve) => {
      const run = () => {
        if (this.regulation) {
          // wait for the regulation to be set; we cannot properly evaluate otherwise
          const publisher = customPublisher || this.publisher
          var baseUrl = 'https://talkingtomandfriends.com/privacy-policy-games'
          if (['o7neo', 'hyperdot'].includes(publisher)) {
            // O7Neo PP links
            baseUrl = 'https://outfit7neo.com'
            if (this.region === 'EU' && ['de', 'fr', 'es', 'pt', 'it', 'en'].includes(this.lang)) {
              resolve(`${baseUrl}/eea/${this.lang}`)
            } else if (this.regulation === 'CCPA') {
              resolve(`${baseUrl}/privacy/ccpa`)
            } else if (this.regulation === 'LGPD') {
              if (this.lang === 'pt') {
                resolve(`${baseUrl}/privacy-brazil/pt`)
              } else {
                resolve(`${baseUrl}/privacy-brazil/en`)
              }
            } else {
              if (this.region === undefined) {
                this.debug.error(
                  'Region & Country not set; fallback privacyUrl served!'
                )
              }
              resolve(`${baseUrl}/privacy/en`)
            }
          } else {
            // TTF PP links
            if (this.storeName && ['bemobi', 'gameloft'].includes(this.storeName)) {
              baseUrl = 'https://talkingtomandfriends.com/customs-privacy/privacy-policy-games'
            }
            if (this.os === platform.WEB) {
              resolve('https://talkingtomandfriends.com/privacy-web/en')
            } else if (this.regulation === 'PIPL' || this.storeName === 'o7nt') {
              // PIPL regulation specific
              resolve(this.getPolicyUrl('pipl_pp'))
            } else if ((this.region === 'EU' && ['de', 'fr', 'es', 'pt', 'it'].includes(this.lang)) || this.regulation === 'ROTW') {
              // link to EEA policies or to localized page for ROTW
              resolve(`${baseUrl}/${this.lang}`)
            } else if (this.regulation === 'LGPD' && this.lang === 'pt') {
              resolve(`${baseUrl}/pt`)
            } else {
              // Use /en for fallback, CCPA and COPPA
              if (this.region === undefined) {
                this.debug.error(
                  'Region & Country not set; fallback privacyUrl served!'
                )
              }
              resolve(`${baseUrl}/en`)
            }
          }
        } else {
          setTimeout(run, 100)
        }
      }
      run()
    })
  }

  executeOnReady(exec) {
    if (this._Vue !== undefined) {
      exec()
    } else {
      setTimeout(() => {
        this.executeOnReady(exec)
      }, 100)
    }
  }

  typeFormat(p, v) {
    if (typeof v === 'object') return JSON.stringify(v)
    if (p === 'os') return platform[v]
    if (p === 'status') return status[v]
    return v
  }

  viewportHeight() {
    return getViewportHeight()
  }

  // screen data retrieval
  getScreen(sId) {
    try {
      return screens.cmp.screens[sId]
    } catch (e) {
      this.debug.error(e)
    }
  }

  //
  getLocalisedContent() {
    try {
      const lang = this.lang
      // if set use language override for collector (certain collectors are only available in a subset of languages and we use overrides)
      if (this.activeCollector in screens.localisation_overrides) {
        if (lang in screens.localisation_overrides[this.activeCollector]) {
          this.setProp('lang', screens.localisation_overrides[this.activeCollector][lang], true)
          if (this.lang === 'ar') this.setProp('dir', 'rtl')
          else this.setProp('dir', 'ltr')
          this.debug.log('DEBUG', `Language override for [${this.activeCollector}] set from ${lang} to ${this.lang}`)
        }
      }
      return screens.lc[this.lang]
    } catch (e) {
      this.debug.error(e)
    }
  }

  isOverAge(ageLimit) {
    if (this.cData.lS.O7Compliance_BirthYear)
      return (
        new Date().getFullYear() -
          Number(this.cData.lS.O7Compliance_BirthYear) >
        ageLimit
      )
    else return this.cData.lS.O7Compliance_AgeLimitPassed
  }

  isPlatform(p) {
    return isPlatform(p, this)
  }

  isRegulation(regulation) {
    return this.regulation?.toLowerCase() === regulation.toLowerCase()
  }

  getStore() {
    return this.storeName
  }

  getActiveCollector() {
    return this.activeCollector
  }

  forceRedraw() {
    // redraw only if customMode is set
    if (this.customMode === 'redrawRequired') {
      this.debug.log('DEBUG', 'redrawRequired: running on affected device')
      const lh = document.body.getBoundingClientRect().height
      const ch = document.getElementsByClassName('regcontainer')[0]
        ? document.getElementsByClassName('layout')[0].getBoundingClientRect()
            .height + 40
        : false
      // we need to perform the fix only on screens with regcontainer
      if (ch) {
        console.log('lh: ' + lh)
        console.log('ch: ' + ch)
        if (lh < ch) {
          this.extendGlobalClass('redrawCorrection', 'fixedfooter')
          this.updateLayoutHeight()
          this.debug.log(
            'DEBUG',
            'redrawRequired: correction not neccessary (screen scrollable lh: ' +
              lh +
              ' < ch: ' +
              ch +
              ')'
          )
        } else {
          // position footer absolutely
          this.extendGlobalClass('redrawCorrection', 'absolutefooter')
          this.updateLayoutHeight()
          // trigger resize event, which forces reflow of DOM
          // window.dispatchEvent(new Event('resize'))
          this.debug.log(
            'DEBUG',
            'redrawRequired: layout correction performed (lh: ' +
              lh +
              ' >= ch: ' +
              ch +
              ')'
          )
        }
        // force layout redraw (resize & padding change)
        window.dispatchEvent(new Event('resize'))
        document.getElementsByClassName('text')[0].style.paddingBottom = '140px'
      } else {
        this.debug.log(
          'DEBUG',
          'redrawRequired: correction not neccessary (screen not affected)'
        )
      }
    }
  }

  // ***************
  // expose onComplianceModuleData to client
  // ***************
  onComplianceModuleData(json) {
    onCMD(json, this)
  }

  setFlag(json) {
    if (typeof json === 'string') json = JSON.parse(json) // parse string to json on android
    // force redraw on screenShown
    if (json.t === 'screenShown' && this.isPlatform('ios')) {
      this.forceRedraw()
    }
    if (json.t === 'backButton' && this.iS === 'PREFERENCE_SETTINGS') {
      if (this.routeHistory.size() === 0) {
        console.log('backButton: close')
        this.native('closeWebApp', true)
      } else {
        console.log('backButton: goBack')
        this._Vue.$router
          .push({ name: this.routeHistory.goBack() })
          .catch((failure) => {})
      }
    }
    this.setProp(json.t, json.p, json.t === 'screenOrientation')
  }

  // expose native call
  native(type, payload) {
    call(type, payload, this)
  }

  openExternalUrl(url) {
    openExternalUrl(url, this)
  }
}
