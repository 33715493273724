// ***************
// TCModel setters & getters
//
// _ctx: global Compliance class context
//
// @author: Primož Bevk
// ***************

// IAB TCF2.0 library
import {
  TCModel,
  TCString,
  PurposeRestriction,
  RestrictionType,
} from '@iabtechlabtcf/core'

import { acceptAllEvent, rejectAllEvent, saveEvent } from '../utils/events'

import { status, navigate } from '../utils/flags'
import {
  setToBinary,
  boolToBinary,
  pubResToObjString,
} from '../utils/tcfparser'

class TCF20 {
  constructor(consentString, _ctx) {
    this._ctx = _ctx
    this.isStateModified = false
    this.update(consentString, false, false, () => {})
  }

  update(consentString, vl, clientInitiated, resolve) {
    this.onResultSent = false
    this.setCurrentConsentScreen('main')
    try {
      if (vl) {
        // if vendor list is supplied; we need to reinitialise GVL,
        this._ctx.GVL.update(vl)
      }
      if (consentString) {
        // Transparency & Consent model
        // we need a blank TCModel first, so it can be included when recomputed from consent string
        this._tcmodel = new TCModel(this._ctx.GVL._gvl)
        // use consent string to rebuild TCModel
        this._tcmodel = this.initTCModelFromComplianceString(consentString)
        this.setTCModelProperties()

        this._ctx.debug.log(
          'DEBUG',
          'init TCModel from ' +
            (consentString ? 'consent string' : 'default state')
        )

        this.initDisclosedPurposes(false) // init disclosed purposes, but don't accept them as we use what's provided in consent string
      } else {
        // if we don't have a consent string, we need to initialise with default settings
        // make sure we have gvl initialised!!
        if (this._ctx.GVL._gvl) {
          this._tcmodel = new TCModel(this._ctx.GVL._gvl)
          this.setTCModelProperties()

          this._ctx.debug.log(
            'DEBUG',
            'init TCModel from ' +
              (consentString ? 'consent string' : 'default state')
          )
          // if we don't initialise by consent string we have to set initial consents as specified in defaults
          this.setInitialConsents()
        }
      }
      if (clientInitiated) this._ctx.setProp('status', status.READY)
      this._ctx.debug.log('INFO', 'tcmodel INIT')
    } catch (e) {
      this._ctx.setProp('status', status.COMPLIANCE_FAIL)
      this._ctx.debug.error(e)
    }
    // we need to set default consent right away!

    resolve(navigate.NAVIGATE)
  }

  setCurrentConsentScreen(screen) {
    this.ccs = screen
  }

  nativeTCModel() {
    return {
      IABTCF_CmpSdkID: this._tcmodel.cmpId,
      IABTCF_CmpSdkVersion: this._tcmodel.cmpVersion,
      IABTCF_PolicyVersion: this._tcmodel.policyVersion,
      IABTCF_gdprApplies: 1,
      IABTCF_PublisherCC: this._tcmodel.publisherCountryCode,
      IABTCF_PurposeOneTreatment: boolToBinary(
        this._tcmodel.purposeOneTreatment
      ),
      IABTCF_UseNonStandardStacks: boolToBinary(
        this._tcmodel.useNonStandardStacks
      ),
      IABTCF_TCString: this.getEncodedComplianceString(),
      IABTCF_VendorConsents: setToBinary(this._tcmodel.vendorConsents),
      IABTCF_VendorLegitimateInterests: setToBinary(
        this._tcmodel.vendorLegitimateInterests
      ),
      IABTCF_PurposeConsents: setToBinary(this._tcmodel.purposeConsents),
      IABTCF_PurposeLegitimateInterests: setToBinary(
        this._tcmodel.purposeLegitimateInterests
      ),
      IABTCF_SpecialFeaturesOptIns: setToBinary(
        this._tcmodel.specialFeatureOptins
      ),
      IABTCF_PublisherConsent: setToBinary(this._tcmodel.publisherConsents),
      IABTCF_PublisherLegitimateInterests: setToBinary(
        this._tcmodel.publisherLegitimateInterests
      ),
      IABTCF_PublisherCustomPurposesConsents: setToBinary(
        this._tcmodel.publisherCustomConsents
      ),
      IABTCF_PublisherCustomPurposesLegitimateInterests: setToBinary(
        this._tcmodel.publisherCustomLegitimateInterests
      ),
      ...pubResToObjString(
        this._ctx.GVL._gvl.vendorIds,
        this._tcmodel.publisherRestrictions
      ),
    }
  }

  setTCModelProperties() {
    this._tcmodel.cmpId = this._ctx.cmp.cmpId
    this._tcmodel.cmpVersion = this._ctx.cmp.cmpVersion
    this._tcmodel.consentScreen = this._ctx.cmp.consentScreen
    this._tcmodel.publisherCountryCode = this._ctx.cmp.publisherCountryCode
    this._tcmodel.supportOOB = false // Out of Band suport
    this._tcmodel.isServiceSpecific = true // set LOCAL scope
  }

  setInitialConsents() {
    // init purposes and legitimate interest (and accept them)
    this.initDisclosedPurposes(true)
    // set Purpose restrictions
    // retrieve all purpose IDs
    const gvlPIDs = Object.keys(this._ctx.GVL._gvl.purposes).map(Number)
    // retrieve all disclosed purpose IDs
    const dpIDs = this._ctx.disclosedPurposes
      .filter(function (dp) {
        return dp.type === 'purpose'
      })
      .map((dp) => dp.id)
    // gvlPIDs - dpIDS = restrictedPurposes
    this._ctx.restrictedPurposes = gvlPIDs.filter(function (pid) {
      return !dpIDs.includes(pid)
    })
    this._ctx.debug.log('DEBUG', 'setInitialConsents()')
  }

  // accept ALL legitimateInterest purposes and vendors as defined in configuration
  initDisclosedPurposes(accept) {
    for (var s in this._ctx.consentScreens) {
      const data = this._ctx.getScreen(this._ctx.consentScreens[s])
      // purpose legitimate interests
      if (data.purposes) {
        this._ctx.debug.log(
          'DEBUG',
          'init ' + this._ctx.consentScreens[s] + ' screen'
        )
        for (var p in data.purposes) {
          this._ctx.disclosedPurposes.push({
            id: data.purposes[p].id,
            type: data.purposes[p].type,
            defaultConsent: data.purposes[p].defaultConsent,
            legitimateInterest:
              this._ctx.consentScreens[s] === 'legitimate-interest',
          })
          if (
            data.purposes[p].type === 'purpose' &&
            data.purposes[p].defaultConsent
          ) {
            if (this._ctx.consentScreens[s] === 'legitimate-interest' && accept)
              this.setPurposeLegitimateInterest(data.purposes[p].id, false)
            else if (accept) this.setPurposeConsent(data.purposes[p].id, false)
          }
        }
      }
    }
    // vendor legitimate interests
    if (accept)
      this.setVendorLegitimateInterest(
        this._ctx.GVL.getVendors('legitimatePurpose', null),
        false
      )
  }

  getEncodedComplianceString() {
    console.log(this._tcmodel)
    return TCString.encode(this._tcmodel)
  }

  initTCModelFromComplianceString(encodedString) {
    return TCString.decode(encodedString, this._tcmodel)
  }

  // return true if user has consented to at least 1 IAB provider
  async hasConsented() {
    return this._tcmodel.vendorConsents.size === await this._ctx.GVL.getVendorsSize()
  }

  getVendorConsent(id) {
    return this._tcmodel.vendorConsents.has(id)
  }

  // id can be number or array
  setVendorConsent(id, isUserAction = true) {
    this._tcmodel.vendorConsents.set(id)
    // set publisher restrictions to vendor when setting consent
    if (Array.isArray(id) || typeof id === 'object')
      id.forEach((item) => this.setPublisherRestrictions(item))
    else this.setPublisherRestrictions(id)
    this.updateState(isUserAction)
  }

  unsetVendorConsent(id, isUserAction = true) {
    this._tcmodel.vendorConsents.unset(id)
    this.updateState(isUserAction)
  }

  emptyVendorConsent(isUserAction = true) {
    this._tcmodel.vendorConsents.empty()
    this.updateState(isUserAction)
  }

  numVendorConsents() {
    return this._tcmodel.vendorConsents.size
  }

  // vendor legitimate interest
  getVendorLegitimateInterest(id) {
    return this._tcmodel.vendorLegitimateInterests.has(id)
  }

  setVendorLegitimateInterest(id, isUserAction = true) {
    this._tcmodel.vendorLegitimateInterests.set(id)
    this.updateState(isUserAction)
  }

  unsetVendorLegitimateInterest(id, isUserAction = true) {
    this._tcmodel.vendorLegitimateInterests.unset(id)
    this.updateState(isUserAction)
  }

  // purpose consent
  getPurposeConsent(id) {
    return this._tcmodel.purposeConsents.has(id)
  }

  setPurposeConsent(id, isUserAction = true) {
    this._tcmodel.purposeConsents.set(id)
    // set vendor consent for purposes
    this.setVendorConsent(
      Object.keys(this._ctx.GVL.getVendors('purpose', id)).map(Number),
      isUserAction
    )
    this.updateState(isUserAction)
  }

  setPurposeLegitimateInterest(id, isUserAction = true) {
    this._tcmodel.purposeLegitimateInterests.set(id)
    this.updateState(isUserAction)
  }

  unsetPurposeLegitimateInterest(id, isUserAction = true) {
    this._tcmodel.purposeLegitimateInterests.unset(id)
    this.updateState(isUserAction)
  }

  // legitimate interest
  getPurposeLegitimateInterest(id) {
    return this._tcmodel.purposeLegitimateInterests.has(id)
  }

  unsetPurposeConsent(id, isUserAction = true) {
    this._tcmodel.purposeConsents.unset(id)
    // unset vendor consent for purposes
    this.unsetVendorConsent(
      Object.keys(this._ctx.GVL.getVendors('purpose', id)).map(Number),
      isUserAction
    )
    this.updateState(isUserAction)
  }

  emptyPurposeConsent() {
    this._tcmodel.purposeConsents.empty()
  }

  setPublisherRestrictions(vendorId) {
    // this.setPublisherRestriction(4, vendorId)
    // for (var rp in this._ctx.restrictedPurposes) {
    //   this.setPublisherRestriction(this._ctx.restrictedPurposes[rp], vendorId)
    // }
  }

  setPublisherRestriction(purposeId, vendorId) {
    const purposeRestriction = new PurposeRestriction()

    purposeRestriction.purposeId = purposeId
    purposeRestriction.restrictionType = RestrictionType.REQUIRE_CONSENT

    this._tcmodel.publisherRestrictions.add(vendorId, purposeRestriction)
  }

  updateState(s) {
    // update state only if app is ready; otherwise drop updateState request
    // (triggered by internal process, not by user as app was not shown to user yet!)
    if (this._ctx.status === status.READY) {
      this._ctx.debug.log('DEBUG', 'TCF2.0 state update')
      this.isStateModified = s
      if (this._ctx.GlobalAcceptReject) {
        this._ctx.GlobalAcceptReject.update()
      } else {
        setTimeout(() => {
          this.updateState(s)
        }, 100)
      }
    }
  }

  acceptAll() {
    acceptAllEvent(this._ctx)
  }

  rejectAll(screen) {
    rejectAllEvent(this._ctx, screen === 'legitimate-interest')
  }

  save() {
    saveEvent(this._ctx)
  }
}
export default TCF20
