// ***************
// GVL Model
//
// _ctx: global Compliance class context
//
// @author: Primož Bevk
// ***************

// IAB TCF2.0 library
import { GVL } from '@iabtechlabtcf/core'
// import data
import purposesDE from '../../../public/v3/latest/purposes-de.json'
import purposesES from '../../../public/v3/latest/purposes-es.json'
import purposesFR from '../../../public/v3/latest/purposes-fr.json'
import purposesIT from '../../../public/v3/latest/purposes-it.json'
import purposesPT from '../../../public/v3/latest/purposes-pt.json'

import { filterObj } from '../utils/core'

class GVLModel {
  constructor(_ctx) {
    this._ctx = _ctx
    this.languages = {
      en: {},
      de: purposesDE,
      es: purposesES,
      fr: purposesFR,
      it: purposesIT,
      pt: purposesPT
    }
    // init Global Vendor List
    this.update(false)

    this._ctx.debug.log('INFO', 'GVL INIT')
  }

  update(vl) {
    if (vl) {
      this._gvl = new GVL(vl)
      this.loadLocalisation(this._ctx.lang)
      this._ctx.debug.log('INFO', 'GVL vendor-list.json updated')
    }
  }

   // cache english localisation, so we retain the ability to dynamically switch back if needed
  cacheENLocalisation() {
    this.languages.en.purposes = this._gvl.purposes
    this.languages.en.specialPurposes = this._gvl.specialPurposes
    this.languages.en.features = this._gvl.features
    this.languages.en.specialFeatures = this._gvl.specialFeatures
    this.languages.en.stacks = this._gvl.stacks
    this.languages.en.dataCategories = this._gvl.dataCategories
  }
  loadLocalisation(lang) {
    if (Object.keys(this.languages).includes(lang)) {
      this.cacheENLocalisation()
      this._gvl.purposes = this.languages[lang].purposes
      this._gvl.specialPurposes = this.languages[lang].specialPurposes
      this._gvl.features = this.languages[lang].features
      this._gvl.specialFeatures = this.languages[lang].specialFeatures
      this._gvl.stacks = this.languages[lang].stacks
      this._gvl.dataCategories = this.languages[lang].dataCategories
      this._ctx.debug.log('INFO', 'GVL localised')
    } else {
      this._ctx.debug.log('INFO', 'GVL localisation failed: language not available')
    }
  }

  getVendors(type, purpose) {
    this._ctx.debug.log('DEBUG', 'fetching vendors')
    if (type === 'purpose')
      return this._gvl.getVendorsWithConsentPurpose(purpose)
    if (type === 'specialPurpose')
      return this._gvl.getVendorsWithSpecialPurpose(purpose)
    if (type === 'all') return this._gvl.vendors
    if (type === 'legitimatePurpose')
      return filterObj(
        this._gvl.vendors,
        (vendor) =>
          vendor.legIntPurposes !== undefined &&
          vendor.legIntPurposes.length > 0
      )
  }

  getVendorsSize() {
    return new Promise((resolve) => {
      const run = () => {
        if (this._gvl) {
          resolve(Object.keys(this._gvl.vendors).length)
        } else {
          setTimeout(run, 100)
        }
      }
      run()
    })
  }

  getVendorsForPurposeType(id, type, isLegitimateInterest) {
    return new Promise((resolve) => {
      const run = () => {
        if (this._gvl) {
          try {
            if (type === 'purpose') {
              resolve(this._gvl.byPurposeVendorMap[id][isLegitimateInterest ? 'legInt' : 'consent'].size)
            } else if (type === 'specialPurpose') {
              resolve(this._gvl.bySpecialPurposeVendorMap[id].size)
            } else if (type === 'feature') {
              resolve(this._gvl.byFeatureVendorMap[id].size)
            } else if (type === 'specialFeature') {
              resolve(this._gvl.bySpecialFeatureVendorMap[id].size)
            }
          } catch (e) {
            // purpose not found
            this._ctx.debug.error(e)
            resolve(0)
          }
          // unsupported type has no vendors
          resolve(0)
        } else {
          setTimeout(run, 100)
        }
      }
      run()
    })
  }

  getPurpose(type, purpose) {
    if (type === 'stack') return this.getConsentStack(purpose)
    if (type === 'purpose') return this.getConsentPurpose(purpose)
    if (type === 'specialPurpose') return this.getConsentSpecialPurpose(purpose)
  }
  getFeature(feature) {
    try {
      return this._gvl.features[feature]
    } catch (e) {
      this._ctx.debug.error(e)
    }
  }

  getDataCategory(dataCategory) {
    try {
      return this._gvl.dataCategories[dataCategory]
    } catch (e) {
      this._ctx.debug.error(e)
    }
  }
  getSpecialFeature(feature) {
    try {
      return this._gvl.specialFeatures[feature]
    } catch (e) {
      this._ctx.debug.error(e)
    }
  }
  getConsentStack(stack) {
    try {
      return this._gvl.stacks[stack]
    } catch (e) {
      this._ctx.debug.error(e)
    }
  }
  getConsentPurpose(purpose) {
    try {
      return this._gvl.purposes[purpose]
    } catch (e) {
      this._ctx.debug.error(e)
    }
  }
  getConsentSpecialPurpose(sPurpose) {
    try {
      return this._gvl.specialPurposes[sPurpose]
    } catch (e) {
      this._ctx.debug.error(e)
    }
  }
}

export default GVLModel
