// ***************
// Compliance TCF Binary Parser
// Utility for parsing consent SETs to BINARY strings
//
// @author: Primož Bevk
// ***************

export const setToBinary = (set) => {
  var b = ''
  set.forEach((hasConsent, vendorId) => {
    b += hasConsent ? '1' : '0'
  })
  return b
}

export const boolToBinary = (bool) => {
  return bool ? '1' : '0'
}

// publisherRestrictions to Object of Strings
export const pubResToObjString = (vendors, restrictions) => {
  var r = {}
  const p = restrictions ? restrictions.getPurposes() : []

  for (var pid in p) {
    r['IABTCF_PublisherRestrictions' + p[pid]] = ''
    vendors.forEach((id) => {
      // if vendor has restriction set for this purpose use it, otherwise default to '0' = NOT_ALLOWED
      r['IABTCF_PublisherRestrictions' + p[pid]] +=
        restrictions.getRestrictionType(id, p[pid]) !== undefined
          ? restrictions.getRestrictionType(id, p[pid])
          : '0'
    })
  }
  return r
}
