// ***************
// Compliance Utils
// Utility Library for Compliance class
//
// @author: Primož Bevk
// ***************
import { call } from './native'
const severity = {
  0: 'DEBUG',
  1: 'INFO',
  2: 'WARNING',
  3: 'ERROR',
  DEBUG: 0,
  INFO: 1,
  WARNING: 2,
  ERROR: 3,
}

export class Logger {
  constructor(ctx) {
    this.level = 0
    this.severity = severity

    this._ctx = ctx
  }
  log(a, b) {
    if (this.severity[a] >= this.level) {
      console.log(a + ' ' + b)
      call('onConsole', a + ' ' + b, this._ctx)
    }
  }
  error(e) {
    this.log('ERROR', e)
    console.error(e)
  }
}

export class Debug {
  constructor(ctx) {
    this.debugMsg = ''
    this.active = true
    this.level = 0
    this.updateTimestamp = new Date()
    this.severity = severity
    this.console = true

    this._ctx = ctx
  }
  log(severity, msg) {
    if (this.severity[severity] >= this.level) {
      // const tf = this.getTimeDifference()
      this.updateTimestamp = new Date()
      this.debugMsg += `<span class="${severity.toLowerCase()}">${this.getTime()} [${severity}] ${msg} <br></span>`
      console.log(severity + ' ' + msg)
      call('onConsole', severity + ' ' + msg, this._ctx)
      this.updateView()
    }
  }
  error(e) {
    this.log('ERROR', e)
    console.error(e)
  }
  out() {
    return this.debugMsg
  }
  getTimeDifference() {
    return new Date().getTime() - this.updateTimestamp.getTime()
  }
  getTime() {
    return (
      this.updateTimestamp.getHours() +
      ':' +
      this.updateTimestamp.getMinutes() +
      ':' +
      this.updateTimestamp.getSeconds() +
      ':' +
      this.updateTimestamp.getMilliseconds()
    )
  }
  toggleConsole(c) {
    this.console = c
  }
  updateView() {
    if (this._ctx.DebugView) {
      this._ctx.DebugView.update()
    } else {
      setTimeout(() => {
        this.updateView()
      }, 100)
    }
  }
}
