export default [
  {
    path: '/',
    name: 'index',
    component: () => import('@views/Index.vue'),
  },
  {
    path: '/preference-settings',
    name: 'preferences',
    component: () => import('@views/PreferenceSettings.vue'),
  },
  {
    path: '/tcf20',
    name: 'tcf20',
    component: () => import('@views/Tcf20.vue'),
  },
  {
    path: '/tcf20/legitimate-interest',
    name: 'tcf20-li',
    component: () => import('@views/tcf20/LegitimateInterest.vue'),
  },
  {
    path: '/tcf20/options',
    name: 'tcf20-options',
    component: () => import('@views/tcf20/Options.vue'),
  },
  {
    path: '/tcf20/vendors',
    name: 'tcf20-vendors',
    component: () => import('@views/tcf20/Vendors.vue'),
  },
  {
    path: '/opt-out',
    name: 'optout',
    component: () => import('@views/OptOut.vue'),
  },
  {
    path: '/ccpa',
    name: 'ccpa',
    component: () => import('@views/Ccpa.vue'),
  },
  {
    path: '/age-gate',
    name: 'agegate',
    component: () => import('@views/AgeGate.vue'),
  },
  {
    path: '/gender-gate',
    name: 'gendergate',
    component: () => import('@views/GenderGate.vue'),
  },
  {
    path: '/consent-gdpr',
    name: 'consentgdpr',
    component: () => import('@views/NonIab.vue'),
  },
  {
    path: '/consent-gdpr/options',
    name: 'gdpr-options',
    component: () => import('@views/noniab/Options.vue'),
  },
  {
    path: '/consent-lgpd',
    name: 'consentlgpd',
    component: () => import('@views/Lgpd.vue'),
  },
  {
    path: '/consent-lgpd/options',
    name: 'lgpd-options',
    component: () => import('@views/lgpd/Options.vue'),
  },
  {
    path: '/consent-noads',
    name: 'consentnoads',
    component: () => import('@views/NoAds.vue'),
  },
  {
    path: '/consent-ads',
    name: 'consentads',
    component: () => import('@views/Ads.vue'),
  },
  {
    path: '/pipl',
    name: 'pipl',
    component: () => import('@views/Pipl.vue'),
  },
  {
    path: '/pipl/confirm',
    name: 'pipl-confirm',
    component: () => import('@views/pipl/Confirm.vue'),
  },
  {
    path: '/pipl/eula',
    name: 'pipl-eula',
    component: () => import('@views/pipl/Eula.vue'),
  },
  {
    path: '/pipl/privacy',
    name: 'pipl-privacy',
    component: () => import('@views/pipl/Privacy.vue'),
  },
  {
    path: '/pipl/third-share-list',
    name: 'pipl-third-share-list',
    component: () => import('@views/pipl/ThirdShareList.vue'),
  },
  {
    path: '/pipl/children-privacy',
    name: 'pipl-children-privacy',
    component: () => import('@views/pipl/ChildrenPrivacy.vue'),
  },
  {
    path: '/pipl/permission-huawei',
    name: 'pipl-permission-huawei',
    component: () => import('@views/pipl/PermissionHuawei.vue'),
  },
  {
    path: '/pipl/permission-oppo',
    name: 'pipl-permission-oppo',
    component: () => import('@views/pipl/PermissionOppo.vue'),
  },
  {
    path: '/pipl/permission-qihoo',
    name: 'pipl-permission-qihoo',
    component: () => import('@views/pipl/PermissionQihoo.vue'),
  },
  {
    path: '/pipl/permission-uc',
    name: 'pipl-permission-uc',
    component: () => import('@views/pipl/PermissionUc.vue'),
  },
  {
    path: '/pipl/permission-vivo',
    name: 'pipl-permission-vivo',
    component: () => import('@views/pipl/PermissionVivo.vue'),
  },
  {
    path: '/pipl/permission-xiaomi',
    name: 'pipl-permission-xiaomi',
    component: () => import('@views/pipl/PermissionXiaomi.vue'),
  },
  {
    path: '/pipl/permission-leyuan233',
    name: 'pipl-permission-leyuan233',
    component: () => import('@views/pipl/PermissionLeyuan233.vue'),
  },
  {
    path: '/pipl/permission-honor',
    name: 'pipl-permission-honor',
    component: () => import('@views/pipl/PermissionHonor.vue'),
  },
  {
    path: '/pipl/permission-tiktok',
    name: 'pipl-permission-tiktok',
    component: () => import('@views/pipl/PermissionTiktok.vue'),
  },
  {
    path: '/pipl/permission-m4399',
    name: 'pipl-permission-m4399',
    component: () => import('@views/pipl/PermissionM4399.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404.vue').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
]
