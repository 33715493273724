<script>
export default {
  data() {
    return {
      layoutClass: '',
    }
  },
  mounted() {
    this.setSelfToLibrary()
  },
  methods: {
    update(c) {
      this.layoutClass = c
    },
    setSelfToLibrary() {
      if (this.$compliance) {
        this.$compliance.App = this
      } else {
        setTimeout(() => {
          this.setSelfToLibrary()
        }, 100)
      }
    },
  },
}
</script>

<template>
  <div id="app" :class="['app', layoutClass]">
    <!--
    Even when routes use the same component, treat them
    as distinct and create the component again.
    -->
    <RouterView :key="$route.fullPath" />
  </div>
</template>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
html {
  -webkit-tap-highlight-color: transparent;
}

body {
  background: #ffffff;
}
</style>
