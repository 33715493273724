/*
 * Delegate
 * Uses WebWorkers to offload work from main thread through workerize wrapper
 * works on Android 4.4+, iOS/Safari 8+
 *
 * @author: Primož Bevk
 */

import workerize from 'workerize'
import { filterObj } from './core'

export class Delegate {
  constructor(_ctx) {
    this._ctx = _ctx
  }

  register(f) {
    return workerize(f)
  }

  async promiseAllVendors() {
    return new Promise((resolve, reject) => {
      resolve(this._ctx.GVL._gvl.vendors)
    })
  }

  async promiseLegitimateInterestVendors() {
    return new Promise((resolve, reject) => {
      resolve(
        filterObj(
          this._ctx.GVL._gvl.vendors,
          (vendor) =>
            vendor.legIntPurposes !== undefined &&
            vendor.legIntPurposes.length > 0
        )
      )
    })
  }

  async getAllVendors() {
    // this is a bit redundant...
    const v = this.register(`
      export async function get(vendorMap) {
        return vendorMap
      }
    `)
    return v.get(this._ctx.GVL._gvl.vendors)
  }

  async getVendors(type, purpose) {
    // implemented parts of @iabtcf/core GVL.ts
    // the GVL object contains a parameter with a Promise,
    // which prevents it to be passed directly to a web worker
    // so we pass only parts of it and re-implement GVL.ts functions
    const v = this.register(`
      export async function get(type, purpose, vendorMap, vendors) {
        if (type === 'purpose')
          return getVendorsWithConsentPurpose(purpose, vendorMap, vendors)
        if (type === 'specialPurpose')
          return gvl.getVendorsWithSpecialPurpose(purpose, vendorMap, vendors)
        if (type === 'all') return vendorMap
        if (type === 'legitimatePurpose')
          return filterObj(
            vendorMap,
            (vendor) =>
              vendor.legIntPurposes !== undefined &&
              vendor.legIntPurposes.length > 0
          )
      }
      function getVendorsWithConsentPurpose(purposeId, vendorMap, vendors) {
        return getFilteredVendors(vendorMap, vendors, 'purpose', purposeId, 'consent')
      }
      function getVendorsWithSpecialPurpose(specialPurposeId, vendorMap, vendors) {
        return this.getFilteredVendors(
          vendorMap,
          vendors,
          'purpose',
          specialPurposeId,
          undefined
        )
      }
      function getFilteredVendors(vendorMap, vendors, purposeOrFeature, id, subType) {
        let vendorSet = new Set()
        const retr = {}
        if (purposeOrFeature === 'purpose' && subType) {
          vendorSet = vendorMap[id][subType]
        } else {
          vendorSet = vendorMap[id]
        }
        vendorSet.forEach((vendorId) => {
          retr[vendorId] = vendors[vendorId]
        })
        return retr
      }
      function filterObj(obj, predicate) {
        const result = {}
        let key

        for (key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key) && predicate(obj[key])) {
            result[key] = obj[key]
          }
        }

        return result
      }
    `)
    if (type === 'purpose') {
      return v.get(
        type,
        purpose,
        this._ctx.GVL._gvl.byPurposeVendorMap,
        this._ctx.GVL._gvl.vendors
      )
    }
    if (type === 'specialPurpose') {
      return v.get(
        type,
        purpose,
        this._ctx.GVL._gvl.bySpecialPurposeVendorMap,
        this._ctx.GVL._gvl.vendors
      )
    } else {
      return v.get(
        type,
        purpose,
        this._ctx.GVL._gvl.vendors,
        this._ctx.GVL._gvl.vendors
      )
    }
  }
}
