// ***************
// Compliance Utils
// Utility Library for Compliance class
//
// @author: Primož Bevk
// ***************
import { platform, modulePath, navigate, background } from './flags'
import { listen } from './native'

export const getViewportHeight = () => {
  var height, sat, sab
  // Store initial orientation
  var axis = Math.abs(window.orientation)
  // And hoist cached dimensions
  var dims = { w: 0, h: 0 }

  /**
   * Creates an element with a height of 100vh since iOS accurately
   * reports vp height (but not window.innerHeight). Then destroy it.
   */
  var createRuler = function () {
    var ruler = document.createElement('div')

    ruler.style.position = 'fixed'
    ruler.style.height = '100vh'
    ruler.style.width = 0
    ruler.style.top = 0

    document.documentElement.appendChild(ruler)

    // Set cache conscientious of device orientation
    dims.w = axis === 90 ? ruler.offsetHeight : window.innerWidth
    dims.h = axis === 90 ? window.innerWidth : ruler.offsetHeight

    // Clean up after ourselves
    document.documentElement.removeChild(ruler)
    ruler = null
  }

  // Measure once
  createRuler()

  // Use appropriate height and consider safe area insets
  sat = getComputedStyle(document.documentElement)
      .getPropertyValue('--sat')
      ?.substring(0, 2)
  sab = getComputedStyle(document.documentElement)
      .getPropertyValue('--sab')
      ?.substring(0, 2)

  height = (Math.abs(window.orientation) !== 90 ? dims.h : dims.w) - (Number(sat?.replace(/\D/g,'')) || 0) - (Number(sab?.replace(/\D/g,'')) || 0)
  return height
}
export const setPublisher = (appId) => {
  if (typeof appId === 'string' && (appId.includes('mythiclegends') || appId.includes('onehelsing'))) {
    return 'o7neo'
  } else if (typeof appId === 'string' && (appId.includes('hds') || appId.includes('hyperdot')))
    return 'hyperdot'
  else return 'outfit7'
}

export const setAppName = (appId, _ctx) => {
  if (_ctx.os === platform.ANDROID) {
    return appId ? appId.split('.')[2] : ''
  } else if (_ctx.os === platform.IOS) {
    return appId ? appId.replace("hds", "").replace("o7neo", "").replace("o7", "") : ''
  } else {
    return appId
  }
}

export const setBackground = (_ctx) => {
  if (_ctx.publisher === 'hyperdot') {
    return background[_ctx.appName]
  } else {
    return background[_ctx.publisher]
  }

}

export const setOrientation = (appId, o) => {
  if (o) return o.toLowerCase()
  if (!appId) return 'portrait'

  var landscapeApps = [
    'com.outfit7.mytalkingtomfriends',
    'o7mytalkingtomfriends',
    'com.hyperdotstudios.swampattack2',
    'hdsswampattack2',
    'com.outfit7.talkingnewsfree',
    'o7talkingnewsipad',
    'o7talkingnews',
    'com.outfit7neo.onehelsing',
    'o7neoonehelsing',
  ]
  return landscapeApps.filter((f) => {
    return appId.includes(f)
  }).length > 0
    ? 'landscape'
    : 'portrait'
}

export const setDeviceType = () => {
  const ua = navigator.userAgent.toLowerCase()
  const isTablet = /(ipad|tablet|(android(?!.*mobile)))/.test(ua)
  if (isTablet) {
    return 'tablet'
  } else {
    return 'mobile'
  }
}

export const isPlatform = (p, _ctx) => {
  return _ctx.os === platform[p.toUpperCase()]
}

export const setPlatform = (os, _ctx) => {
  if (os) {
    os = os.toLowerCase()
    if (os === 'iphone' || os === 'ipad' || os === 'ipod') {
      return platform.IOS
    } else if (os === 'android') {
      return platform.ANDROID
    } else if (os === 'web') {
      listen(_ctx)
      return platform.WEB
    }
  } else {
    var ua = navigator.userAgent.toLowerCase()
    if (ua.indexOf('iphone') > -1) return platform.IOS
    if (ua.indexOf('ipod') > -1) return platform.IOS
    if (ua.indexOf('ipad') > -1) return platform.IOS
    if (ua.indexOf('android') > -1) return platform.ANDROID
    // if (ua.indexOf("msie") > -1 || ua.indexOf("iemobile") > -1 || ua.indexOf("wpdesktop") > -1 || ua.indexOf("windows phone") > -1) return 'msie'
  }
  return platform.UNSUPPORTED_OS
}

export const osVersion = (v, _ctx) => {
  if (v === undefined || v === null || v === false) return false
  // take major version and cast to Number
  if (typeof v === 'string') {
    const ver = Number(v.split('.')[0])
    // Android versions
    if (ver <= 5 && _ctx.os === platform.ANDROID)
      _ctx.setProp('legacyMode', true)
    if (ver < 7 && _ctx.os === platform.ANDROID)
      _ctx.setProp('customMode', 'scrollToUnsupported')
    // iOS versions
    if (ver <= 10 && _ctx.os === platform.IOS)
      _ctx.setProp('customMode', 'ios10', true)
    if (ver >= 12 && ver < 13 && _ctx.os === platform.IOS)
      _ctx.setProp('customMode', 'redrawRequired', true)
    return ver
  } else return v
}

export const cmVersion = (v, _ctx) => {
  if (v === undefined || v === null || v === false) return false
  // parse version number to float.
  // Patch version is concatenated to minor version
  // Ex: x.y.z becomes x.yz
  // this is needed so we can transform version to float for easy comparison
  if (typeof v === 'string') {
    var t = 0
    return parseFloat(v.replace(/\./g, (match) => (++t === 2 ? '' : match)))
  } else return v
}

export const storeName = (v, _ctx) => {
  if (_ctx.publisher === 'hyperdot') return 'hds'
  if (v === undefined || v === null || v === false) return 'o7'
  if (v && v.length === 0) return 'o7'
  return v?.toLowerCase()
}

export const setLocale = (loc, _ctx) => {
  const supportedLocales = [
    'en',
    'zh',
    'ja',
    'ko',
    'de',
    'fr',
    'es',
    'pt',
    'it',
    'ru',
    'tr',
    'ar',
    'hi',
    'zh-hant',
    'vi',
    'id',
    'th'
  ]
  loc = loc
    ? loc.toLowerCase()
    : navigator.language
    ? navigator.language.toLowerCase()
    : navigator.userLanguage.toLowerCase()
  if (loc.substring(0, 2) === 'ar') _ctx.setProp('dir', 'rtl')
  else _ctx.setProp('dir', 'ltr')
  if (
    ['zh-hant', 'zh-trad', 'zh_tw', 'zh-tw'].includes(loc) ||
    loc.includes('zh-hant') ||
    loc.includes('zh-trad') ||
    loc.includes('zh_tw')
  )
    return 'zh-hant'
  else
    return supportedLocales.includes(loc.substring(0, 2))
      ? loc.substring(0, 2)
      : 'en'
}
export const setRegion = (cc, _ctx) => {
  const eu = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
    'GB',
  ]
  cc = cc !== undefined ? cc.toUpperCase() : 'ZZ'
  _ctx.setProp('country', cc)

  if (eu.includes(cc)) return 'EU'
  else if (cc === 'US') return 'US'
  else return 'ROW'
}

export const checkNestedKeys = (obj, level, ...rest) => {
  if (obj === undefined) return false
  if (rest.length === 0 && Object.prototype.hasOwnProperty.call(obj, level))
    return true
  return checkNestedKeys(obj[level], ...rest)
}

export const getNestedKeys = (obj, ...args) => {
  return args.reduce((obj, level) => obj && obj[level], obj)
}

export const filterObj = function (obj, predicate) {
  const result = {}
  let key

  for (key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && predicate(obj[key])) {
      result[key] = obj[key]
    }
  }

  return result
}

export const resValidate = (cmd, module) => {
  cmd.sP = cmd.sP || {}
  cmd.sP[module] = cmd.sP[module] || {}
  cmd.sP[module].r = cmd.sP[module].r || {}
  cmd.lS = cmd.lS || {}
  return cmd
}

// retrieve relevant sPC from array
const getSPC = (module, spc) => {
  if (module === 'preference-settings') return spc // menu module requires array of all; others need only their specific sPC
  for (const s in spc) {
    if (spc[s].id === module) return spc[s]
  }
  return {}
}

export const initModule = (module, _ctx) => {
  var resolve = navigate.WAIT
  const spc = getSPC(_ctx.moduleType, _ctx.cData.sPC)
  // set countryCode globally
  if (typeof spc === 'object' && spc.p) {
    _ctx.setProp('region', setRegion(spc.p.countryCode, _ctx))
    _ctx.setProp(
      'sentryCaptureMessageEnabled',
      spc.p.sentryCaptureMessageEnabled !== undefined
        ? spc.p.sentryCaptureMessageEnabled === 'true'
        : false
    )
  }

  if (module !== 'preference-settings') _ctx.setProp('gts', spc.p.gts) // set gts to be globally available
  // initialise requested module
  if (
    module === 'consent_gdpr'  ||
    module === 'consent_lgpd'  ||
    module === 'consent_noads' ||
    module === 'consent_ads'
  ) {
    _ctx.nonIAB.update(
      _ctx.cData.nonIAB,
      getNestedKeys(spc, 'p', 'nIV'),
      (r) => {
        resolve = r
      }
    )
  } else if (module === 'consent_tcf20') {
    if (_ctx.cData.nonIAB) {
      resolve = navigate.WAIT
      _ctx.nonIAB.update(
        _ctx.cData.nonIAB,
        getNestedKeys(spc, 'p', 'nIV'),
        (r) => {
          resolve = r
        }
      )
    }
    _ctx.TCF20.update(
      getNestedKeys(_ctx.cData, 'sP', 'consent_tcf20', 'r', 'consentString'),
      _ctx.cData.gvl,
      true,
      (r) => {
        resolve = r
      }
    )
  } else if (module === 'age_gate') {
    _ctx.AgeGate.update(
      getNestedKeys(_ctx.cData, 'sP', 'age_gate', 'r'),
      getNestedKeys(spc, 'p'),
      (r) => {
        resolve = r
      }
    )
  } else if (module === 'preference-settings') {
    _ctx.PreferenceSettings.update(spc, (r) => {
      resolve = r
    })
  } else if (module === 'consent_ccpa') {
    _ctx.CCPA.update(getNestedKeys(_ctx.cData, 'lS'), (r) => {
      resolve = r
    })
  } else if (module === 'optout') {
    _ctx.OptOut.update(
      getNestedKeys(_ctx.cData, 'lS'),
      getNestedKeys(spc, 'p'),
      (r) => {
        resolve = r
      }
    )
  } else if (module === 'gender_gate') {
    _ctx.GenderGate.update(getNestedKeys(_ctx.cData, 'lS'), (r) => {
      resolve = r
    })
  } else if (module === 'consent_pipl') {
    _ctx.Pipl.update(
      getNestedKeys(_ctx.cData, 'lS'),
      getNestedKeys(spc, 'p'),
      (r) => {
        resolve = r
      }
    )
  }
  const wait = setInterval(() => {
    if (resolve === navigate.NAVIGATE) {
      resolvePath(module, _ctx)
      clearInterval(wait)
    } else if (resolve === navigate.DONT_NAVIGATE) {
      clearInterval(wait)
    }
  }, 100)
}

// resolve path (route to appropriate module if not already there)
export const resolvePath = (module, _ctx) => {
  if (_ctx._Vue.$router.currentRoute.path !== modulePath[module]) {
    _ctx.debug.log(
      'INFO',
      '[' +
        module +
        '] navigate from: ' +
        _ctx._Vue.$router.currentRoute.path +
        ' to: ' +
        modulePath[module]
    )
    _ctx._Vue.$router.push({ path: modulePath[module] }).catch((failure) => {})
  }
  _ctx.native('onShown', { screenId: module, background: _ctx.backgroundColor }) // send even if resolving to same path (case for first preference collector, which is opened in its own path already)
}
